/* globals fbq document */
// Some basic abstract helper functions

import React from 'react';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { setUserWorkplace } from './placeHelper';
import { isNonAutomobileType } from './vehicleHelper';
import { loadClientLocationDetailsAndPriceList } from './brain2';
import * as rudderAnalytics from 'rudder-sdk-js';

export let Z3P_CONFIG = {};

export const priceOr = (price, defaultValue) => {
  if (price > 0) {
    return `$${price.toFixed(2)}`;
  }
  return defaultValue;
};

export const determineIfSingleTotalColumn = (allConfirmedServices) => {
  let hasLaborCost = false;
  let hasPartsCost = false;
  allConfirmedServices.forEach((s) => {
    hasLaborCost = hasLaborCost || s.labor_cost_dollars > 0;
    hasPartsCost = hasPartsCost || s.parts_cost_dollars > 0;
  });
  return !(hasPartsCost && hasLaborCost);
};

export function setZ3PConfig(config) {
  Z3P_CONFIG = config;
  return Z3P_CONFIG;
}

// Return a range of dates between (and including) two given dates
export function getDateArray(startDate, stopDate, includeWeekends = true) {
  const dateArray = [];
  const currentDate = startDate;
  while (currentDate <= stopDate) {
    // if we're including weekends or if this day is not a saturday or sunday
    if (includeWeekends || ![0, 6].includes(currentDate.getDay())) {
      // add this date to our array
      dateArray.push(new Date(currentDate));
    }
    // move on to the next date
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

// Given an array of strings, return a ul/li list of HTML elements
export function arrayToHTMLList(arr, style = {}) {
  return (
    <ul>
      {arr.map((text) => (
        <li key={text} style={{ marginLeft: 40, ...style }}>
          &#8226; {text}
        </li>
      ))}
    </ul>
  );
}

export function roundToTwoDecimals(number) {
  return Math.round(number * 100) / 100.0;
}

export function formatPrice(amountDollars) {
  if (amountDollars === null || amountDollars === undefined) {
    return '';
  }
  // Try to format with 0 decimals (as long as it's a round number)
  if (Math.round(amountDollars) === amountDollars) {
    return `$${amountDollars.toFixed(2)}`;
  }
  // Otherwise, format with 2 decimals
  return `$${amountDollars.toFixed(2)}`;
}

// Input: '17:00:00'
// Output: '5:00 PM'
export function datetimeToTime(time) {
  const hour = parseInt(time.substring(0, 2), 10);
  const min = parseInt(time.substring(3, 5), 10);
  const minPad = min < 10 ? '0' : '';
  const ampm = hour < 12 ? 'AM' : 'PM';
  const hour12 = ((hour - 1) % 12) + 1;

  return `${hour12}:${minPad}${min} ${ampm}`;
}

const padMinuteWith2Digits = (number) => {
  return `${number < 10 ? '0' : ''}${number}`;
};

// Input: 13.5
// Output: '1:30 PM'
export function hoursToTimeString(val) {
  const hr = Math.floor(val);
  const mn = Math.floor((val % 1) * 60);
  const ampm = hr >= 12 ? 'PM' : 'AM';
  const formattedHour = hr > 12 ? hr - 12 : hr;
  return `${formattedHour === 0 ? '12' : formattedHour}:${padMinuteWith2Digits(
    mn,
  )} ${ampm}`;
}

// Input: '1:30 PM'
// Output: 13.5
export function timeStringToHours(time) {
  let [hour, minute, ampm] = time.split(/:| /);
  hour = Number(hour);
  if (hour === 12) {
    hour -= 12;
  }
  if (ampm === 'PM') {
    hour += 12;
  }
  const hourFraction = parseInt(minute) / 60;
  hour += hourFraction;
  return roundToTwoDecimals(hour);
}

// Round a datetime based on resolution, where resolution is the interval to round to (as a number of minutes).
// For example, if resolution is 15 (the default), then we would round to 1:00, 1:15, 1:30, 1:45, 2:00, etc.
export function roundMinutesUp(datetime, resolution = 15) {
  const dt = moment(datetime);
  const oldMinutes = dt.minutes();
  const newMinutes = Math.ceil(oldMinutes / resolution) * resolution;
  const difference = newMinutes - oldMinutes;
  return dt.add(difference, 'minutes');
}

export function roundMinutesDown(datetime, resolution = 15) {
  const dt = moment(datetime);
  const oldMinutes = dt.minutes();
  const newMinutes = Math.floor(oldMinutes / resolution) * resolution;
  const difference = newMinutes - oldMinutes;
  return dt.add(difference, 'minutes');
}

export function alphabeticalObjectSort(
  listOfObjects,
  sortByKey,
  reverse = false,
) {
  listOfObjects.sort((a, b) => {
    const aValue = a[sortByKey] && a[sortByKey].toLowerCase();
    const bValue = b[sortByKey] && b[sortByKey].toLowerCase();
    const r = reverse ? -1 : 1;
    if (aValue > bValue) return 1 * r;
    if (aValue < bValue) return -1 * r;
    return 0;
  });
  return listOfObjects;
}

// Checks if an object is iterable
// Based on https://stackoverflow.com/a/32538867
export function isIterable(obj) {
  return (
    obj !== null &&
    obj !== undefined &&
    typeof obj[Symbol.iterator] === 'function'
  );
}

// 98% of colors fall in the non-other categories: http://www.infoplease.com/world/transportation/most-popular-car-colors.html
// corroborated: https://en.wikipedia.org/wiki/Car_colour_popularity
export function getCommonVehicleColors() {
  return [
    'Black',
    'Blue',
    'Brown',
    'Gold',
    'Gray',
    'Green',
    'Orange',
    'Purple',
    'Red',
    'Silver',
    'White',
    'Yellow',
    'Other',
  ];
}

export function getCommonVehicleColorsShortList() {
  return ['Black', 'Blue', 'Gray', 'Red', 'Silver', 'White', 'Other'];
}

export function setPageTitle(title) {
  document.title = Z3P_CONFIG.customer_facing_name
    ? `${Z3P_CONFIG.customer_facing_name}: ${title}`
    : `${title}`;
}

export function getEmailSupportLink() {
  return (
    <a href={`mailto:${Z3P_CONFIG.customer_facing_email}`}>
      {Z3P_CONFIG.customer_facing_email}
    </a>
  );
}

export function getPhoneSupportLink() {
  const rawPhone = Z3P_CONFIG.customer_facing_phone_raw;
  const customerFacingPhone = Z3P_CONFIG.customer_facing_phone;
  if (!rawPhone || !customerFacingPhone) return null;
  return <a href={`tel:+${rawPhone}`}>{customerFacingPhone}</a>;
}

export function getURLQuery() {
  const currentLocation = browserHistory && browserHistory.getCurrentLocation();
  return currentLocation && currentLocation.query;
}

// Misspellings based on customer usage (from Chartio)
// https://chartio.com/zippity/sandbox-maxzippitycarscom-coupons/
export const misspelledCoupons = {
  FIST15: 'FIRST15',
  FRIST15: 'FIRST15',
  SHARES: 'SHARE5',
  SHARES5: 'SHARE5',
  TRYS: 'TRY5',
};

export const currentPageInfo = {
  URL: window.location.href,
  PATH: window.location.pathname,
  TITLE: document.title,
};

export function tracRudderStackkEvent(eventName, eventProperties) {
  rudderAnalytics.track(eventName, eventProperties);
}

export function getChannel() {
  const userAgent = navigator.userAgent;

  if (/mobile|android|touch|webos|hpwos/i.test(userAgent)) {
    return 'mobile';
  } else {
    return 'web';
  }
}

export function isCouponAlreadyUsed(couponCode, scheduledWorks, couponList) {
  const matchingCoupon = couponList.find((c) => c.coupon_code === couponCode);
  if (matchingCoupon?.can_use_multiple_times) return false;
  const couponUpper = couponCode.toUpperCase();
  return (
    scheduledWorks &&
    scheduledWorks.some(
      (sw) =>
        sw.coupon_code &&
        sw.coupon_code.toUpperCase() === couponUpper &&
        sw.status !== 'cancelled',
    )
  );
}

export function changeCouponHelper(e, scheduledWorks, couponList) {
  const couponCode = e.target.value.toUpperCase().trim();
  let couponMessage = '';

  if (isCouponAlreadyUsed(couponCode, scheduledWorks, couponList)) {
    couponMessage =
      'Sorry, you already used that coupon in a previous booking.';
  } else if (misspelledCoupons[couponCode]) {
    const corrected = misspelledCoupons[couponCode];
    if (!isCouponAlreadyUsed(corrected, scheduledWorks, couponList)) {
      couponMessage = `Did you mean ${corrected}?`;
    }
  }
  return [couponCode, couponMessage];
}

export function mediaQueryMaxWidth600() {
  return window.matchMedia('(max-width: 600px)');
}

export function isFromQuote(cart) {
  return cart && cart.some((service) => service.quote_option_id);
}

export function formatLicenseVin(v) {
  return v.replace(/[^0-9a-z -]/gi, '').toUpperCase();
}

export function shouldAutoSelectLocation(serviceLocations, clientLocations) {
  return (
    serviceLocations.length === 1 &&
    !serviceLocations.includes('home') &&
    clientLocations.length === 1
  );
}

export function setUserWorkplaceAndRedirect(
  clientLocationId,
  dispatch,
  skip_simple_services_to,
  is_skipping_vehicle,
  vehicle,
) {
  const skipToNonAutomobileServices = isNonAutomobileType(vehicle?.vehicleType);
  setUserWorkplace(dispatch, clientLocationId, vehicle?.vehicle_id, () => {
    dispatch({
      type: 'SET_HOME_ADDRESS',
      address: {},
      addressIDSelected: null,
      addressNameSelected: null,
    });
    // If a non-automobile vehicle is selected, show services specific to that vehicle type in list view (using mechanical services page)
    if (skipToNonAutomobileServices || is_skipping_vehicle) {
      return browserHistory.push(`/pricing/services`);
    }
    if (
      skip_simple_services_to &&
      ['services', 'detailing', 'tires', 'diagnostic'].includes(
        skip_simple_services_to,
      )
    ) {
      return browserHistory.push(`/pricing/${skip_simple_services_to}`);
    }
    return browserHistory.push('/pricing/simple');
  });
}

export const shouldUseFacebookPixel = (z3pEnv) => {
  return (
    (z3pEnv === 'zippity' ||
      z3pEnv === 'dirty-burd' ||
      z3pEnv === 'bigs-mobile-detailing' ||
      z3pEnv === 'spruce-detail') &&
    typeof fbq !== 'undefined'
  );
};

// Input 0.3
// Output 30
export const calcLaborMinutes = (hours) => {
  const min = hours * 60;
  if (min >= 0 && min <= 15) {
    return 15;
  }
  if (min > 15 && min <= 30) {
    return 30;
  }
  return 45;
};

// Input 9.2
// Output 9.25
export const roundLaborHours = (hours) => {
  const isDecimal = hours.indexOf('.') === 1;
  if (isDecimal) {
    const [hourSplit, minuteSplit] = hours.split('.');
    const minutes = Number(`0.${minuteSplit}`) * 60;
    if (minutes === 0) {
      return Number(hourSplit);
    }
    if (minutes > 0 && minutes <= 15) {
      return Number(hourSplit) + 0.25;
    }
    if (minutes > 15 && minutes <= 30) {
      return Number(hourSplit) + 0.5;
    }
    return Number(hourSplit) + 0.75;
  }
  return hours;
};

export const creditCardInfoToDescription = (
  ccBrand,
  ccLast4,
  last4Prefix = ' ending in x',
) => {
  if (!!ccBrand && ccBrand !== '?' && !!ccLast4 && ccLast4 !== '????') {
    return `${ccBrand}${last4Prefix}${ccLast4}`;
  }
  return '';
};

export const possibleProStatuses = {
  active: 'Active',
  churned: 'Churned',
  delinquent: 'Delinquent',
  paused: 'Paused',
};

export function convertPhoneFormat(phone) {
  const cleanedPhone = `${phone}`.replace(/\D/g, '');
  const match = cleanedPhone.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const number = `(${match[2]}) ${match[3]}-${match[4]}`;
    return number;
  }
  return phone;
}

export const KEY_LOCATION_OPTIONS = {
  contact: "Call/text me when you arrive. I'll hand the keys to you.",
  insideVehicle: 'I’ll leave them inside the vehicle.',
  frontDoor: 'I’ll leave them near the front door, or under the doormat.',
  mailbox: 'I’ll leave them in the mailbox.',
  tire: 'I’ll leave them on the front driver’s side tire.',
  other: 'Other',
};

export function handleRescheduleClick(sw, dispatch, vehicle, token = null) {
  dispatch({
    type: 'SET_CART',
    cart: sw.services,
  });

  const keyLocation = Object.entries(KEY_LOCATION_OPTIONS).find(
    ([optionName, text]) => text === sw.key_exchange_note,
  );

  dispatch({
    type: 'SET_KEY_LOCATION',
    keyLocation,
  });

  const schedule = {
    note: sw.customer_note,
    date: sw.date,
    swId: sw.scheduled_work_id,
    serviceVehicleId: sw.service_vehicle_id,
    isHandraise: sw.is_handraise,
    ...(sw.planned_arrival_time && {
      plannedArrivalTime: datetimeToTime(sw.planned_arrival_time),
    }),
    ...(sw.planned_departure_time && {
      plannedDepartureTime: datetimeToTime(sw.planned_departure_time),
    }),
    ...(sw.confirmed_appointment_start_time && {
      confirmedAppointmentStartTime: sw.confirmed_appointment_start_time,
    }),
    token,
  };

  dispatch({ type: 'SET_SCHEDULE', schedule });

  dispatch({
    type: 'SET_CHANGE_BOOKING',
    changeBooking: true,
  });

  dispatch({ type: 'SET_GO_BACK', goBack: true });

  const locationID = sw.client_location_id;

  if (!locationID) {
    dispatch({
      type: 'SET_HOME_ADDRESS',
      address: sw.customer_address || {},
      addressIDSelected: sw.customer_address && sw.customer_address.id,
      addressNameSelected:
        sw.customer_address &&
        (sw.customer_address.display_name ||
          sw.customer_address.address_line_1),
    });
    browserHistory.push('/pricing/schedule');
  } else {
    dispatch({
      type: 'SET_HOME_ADDRESS',
      address: {},
      addressIDSelected: null,
      addressNameSelected: null,
    });
    const vehicle_id = vehicle?.vehicle_id;
    loadClientLocationDetailsAndPriceList(
      dispatch,
      locationID,
      vehicle_id,
    ).then(() => {
      browserHistory.push('/pricing/schedule');
    });
  }
}
export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + ( days * 24 * 60 * 60 * 1000 ));
        expires = "; max-age=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

/* globals window */

import React, { Component } from 'react';
import md5 from 'md5';
import qstring from 'query-string';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { CardPanel, Button } from 'react-materialize';
import Zippity from './components/Zippity';
import { apiPost, hasAccessToken, storeAccessToken } from '../brainApi';
import { loadClientLocationDetailsAndPriceList } from '../brain2';
import { stateAbbreviationToName } from './components/UsStates';
import { setPageTitle, tracRudderStackkEvent, currentPageInfo } from '../helper';

class Login extends Component {
  state = { processing: false, longFailureMessage: null };

  componentWillMount() {
    setPageTitle('Login');
  }

  componentDidMount() {
    if (hasAccessToken()) {
      browserHistory.push(this.getRedirect());
    }
  }

  getRedirect() {
    const { location } = this.props;
    if (qstring && location && location.search) {
      const queryString = qstring.parse(location.search);
      if (queryString && queryString.redirect) {
        const potential = decodeURIComponent(queryString.redirect);
        if (potential.startsWith('/')) {
          return potential;
        }
      }
    }
    return '/account';
  }

  handleEmailChange = (event) => {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_EMAIL', email: event.target.value });
  };

  handleSubmit = (event) => {
    const { dispatch, homeAddress, vehicle } = this.props;
    event.preventDefault();

    this.setState({ longFailureMessage: '', processing: true });

    const email = this.email.value;
    const password = this.password.value;

    const payload = {
      email,
      md5: md5(password),
    };

    apiPost('/user/sign-in', payload).then((response) => {
      if (response.status !== 200) {
        response.text().then((x) => {
          this.setState({
            longFailureMessage: 'Invalid username or password.',
            processing: false,
          });
          console.log(x);
        });
        return;
      }

      response.json().then((json) => {
        const {
          default_client_location: defaultClientLocation,
          customer,
          vehicles,
          scheduledWorks, // Inconsistency: Brain2 sends this as camelCase
        } = json;
        
        tracRudderStackkEvent('Zippity_Log_in_succesful', {
          description: `User submit the button`,
          button_name: 'Sign In',
          account_id:customer.customer_id,
          account_type: 'Customer',
          page_information: {
            path:currentPageInfo['PATH'],
            url:currentPageInfo['URL'],
            title:document.title
          }
        });
        
        const clientLocationShortName =
          defaultClientLocation && defaultClientLocation.short_name;
        const clientLocationId =
          defaultClientLocation && defaultClientLocation.client_location_id;
        const customerUsState =
          defaultClientLocation && defaultClientLocation.state;

        dispatch({ type: 'SET_ACCOUNT', account: customer });

        dispatch({ type: 'SET_VEHICLES', vehicles });

        storeAccessToken(json.token);

        dispatch({
          type: 'SET_LOCATION',
          location: clientLocationShortName,
          selectedLocationAlias: clientLocationShortName,
          clientLocationId,
          customerUsState:
            customerUsState && stateAbbreviationToName(customerUsState),
        });

        const vehicle_id = vehicle?.vehicle_id;
        loadClientLocationDetailsAndPriceList(
          dispatch,
          clientLocationId,
          vehicle_id,
        );

        dispatch({
          type: 'SET_SCHEDULED_WORKS',
          scheduledWorks,
        });

        if (homeAddress && homeAddress.address) {
          apiPost('/customer/address', {
            ...homeAddress.address,
            is_deleted: false,
            is_primary: false,
          })
            .then((r) => r.json())
            .then((j) => {
              if (homeAddress.addressNameSelected) {
                dispatch({
                  type: 'SET_HOME_ADDRESS',
                  ...homeAddress,
                  address: j,
                  addressIDSelected: j.id,
                });
              }
              browserHistory.push(this.getRedirect());
            });
        } else {
          browserHistory.push(this.getRedirect());
        }
      });
    });
  };

  render() {
    let submitClass = 'right btn-large btn header';
    const { longFailureMessage, processing } = this.state;
    const { z3pConfiguration } = this.props;
    const {
      customer_facing_name: serviceProviderName,
      customer_facing_name_short: serviceProviderNameShort,
    } = z3pConfiguration;

    if (processing) {
      submitClass += ' btn disabled ';
    }

    return (
      <Zippity active="login">
        <div id="login">
          <div className="row">
            <div className="col s12 center">
              <h2>Welcome Back!</h2>
            </div>
          </div>
          <div className="row login-form">
            {longFailureMessage && (
              <div className="container clearfix">
                <CardPanel className="red lighten-4 error_msg">
                  {longFailureMessage}
                </CardPanel>
              </div>
            )}
            <div className="col s12 input-wrapper neue">
              <form onSubmit={this.handleSubmit}>
                <div className="input-field col m12">
                  <input
                    ref={(i) => (this.email = i)}
                    id="email"
                    name="email"
                    type="email"
                    className="validate input"
                    required="required"
                    value={this.props.account.email}
                    onChange={this.handleEmailChange}
                  />
                  <label className="active" htmlFor="email">
                    Email
                  </label>
                </div>
                <div className="input-field col m12">
                  <input
                    ref={(i) => (this.password = i)}
                    id="password"
                    name="password"
                    type="password"
                    className="validate input"
                    required="required"
                  />
                  <label htmlFor="password">Password</label>
                </div>
                <div>
                  <a href="/forgot-password" className="forgot-password">
                    Forgot password?
                  </a>
                  <Button
                    disabled={processing}
                    className={submitClass}
                    type="submit"
                    name="action"
                  >
                  Sign In
                  </Button>
                </div>
              </form>

              <div className="clearfix" />

              <div className="center">
                <div className="create-account-container">
                  <h4 className="new-to-zippity">First time here?</h4>
                  <a href="javascript:;"
                    onClick={() => {
                      const { dispatch } = this.props;
                      dispatch({ type: 'CLEAR_USER' });
                      browserHistory.push('/pricing');
                    }}
                    className="waves-effect waves-light create-account hover-underline-animation"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    homeAddress: state.ui.homeAddress,
    vehicle: state.ui.pricing,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Login);

import { applyMiddleware, compose, createStore } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist';
import update from 'immutability-helper';
import Moment from 'moment';

const INITIAL_STATE = {
  ui: {
    pricing: {},
    account: {},
    keyosk: null,
    parking: null,
    schedule: {},
    cart: [],
    vehicles: [],
    scheduledWorks: [],
    clientLocations: [],
    referrer: {},
    diagnostic: {},
    customerLocation: {},
    homeAddress: {},
    dedicatedParking: true,
    priceList: [],
    taxRate: 0,
    compatibleDates: [],
    availableScheduleTypes: [],
    changeBooking: false,
    messages: {},
    coupon: null,
    couponList: [],
    customDiscount: null,
    tireProducts: {},
    tireDetails: {},
    tireNotes: {},
    autoSelectServiceOfferingIds: [],
    detailingNote: '',
    recallList: [],
    serviceAreas: [],
    activePaymentMethods: [],
    selectedPaymentMethod: null,
    z3pConfiguration: {},
    isPostalCodeInUV: false,
    proConfiguration: {},
    urlLoadServiceId: 0,
  },
};

// for this version we just put all date in here, later we need to get it by day
function reducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_LOCATION':
      return update(state, {
        ui: {
          // customerLocation holds client location info (not home address info)
          customerLocation: {
            location: { $set: action.location },
            clientLocationId: { $set: action.clientLocationId },
            selectedLocationAlias: { $set: action.selectedLocationAlias },
            customerUsState: { $set: action.customerUsState },
          },
          account: {
            default_client_location_id: {
              $set: action.clientLocationId || null,
            },
          },
        },
      });
    case 'SET_ALL_KEYOSKS':
      return update(state, {
        ui: { customerLocation: { allKeyosks: { $set: action.allKeyosks } } },
      });
    case 'SET_ALL_PARKING_ZONES':
      return update(state, {
        ui: {
          customerLocation: {
            allParkingZones: { $set: action.allParkingZones },
          },
        },
      });

    case 'SET_HOME_ADDRESS':
      return update(state, {
        ui: {
          homeAddress: {
            addressIDSelected: {
              $set: action.addressIDSelected,
            },
            addressNameSelected: {
              $set: action.addressNameSelected,
            },
            address: { $set: action.address },
            keyLocationNote: { $set: action.keyLocationNote },
          },
          taxRate: { $set: action.address.tax_rate_percent },
        },
      });

    case 'SET_EMAIL':
      return update(state, {
        ui: {
          account: {
            email: { $set: action.email },
          },
        },
      });

    case 'SET_REFERRER':
      return update(state, {
        ui: {
          referrer: {
            source: { $set: action.refSource },
            code: { $set: action.referralCode },
          },
        },
      });

    case 'SELECT_VEHICLE_TYPE': {
      return update(state, {
        ui: {
          pricing: { vehicleType: { $set: action.vehicleType } },
        },
      });
    }

    case 'SELECT_YEAR': {
      return update(state, {
        ui: {
          pricing: { year: { $set: action.year } },
        },
      });
    }

    case 'SELECT_MAKE': {
      return update(state, {
        ui: {
          pricing: {
            year: { $set: state.ui.pricing.year },
            make: { $set: action.makeName },
            makeId: { $set: action.makeId },
          },
        },
      });
    }

    case 'SELECT_MODEL': {
      return update(state, {
        ui: {
          pricing: {
            model: { $set: action.modelName },
            modelId: { $set: action.modelId },
            $unset: ['trim'],
          },
        },
      });
    }
    case 'SELECT_TRIM':
      return update(state, {
        ui: {
          pricing: {
            trim: { $set: action.submodelName },
            submodel_name: { $set: action.submodelName },
            submodelId: { $set: action.submodelId },
            motor_vehicle_id: { $set: action.vehicleId },
            motor_base_vehicle_id: { $set: action.baseVehicleId },
            $unset: ['vehicle_id', 'color', 'engine'],
          },
        },
      });

    case 'SELECT_ENGINE':
      return update(state, {
        ui: {
          pricing: {
            engine: { $set: action.engine },
            $unset: ['vehicle_id', 'color'],
          },
        },
      });

    case 'SELECT_COLOR':
      return update(state, {
        ui: {
          pricing: { color: { $set: action.color } },
        },
      });

    case 'SELECT_HEAVY_DUTY_ENGINE_MAKE':
      return update(state, {
        ui: {
          pricing: {
            heavy_duty_engine_make: { $set: action.heavy_duty_engine_make },
          },
        },
      });

    case 'SELECT_HEAVY_DUTY_ENGINE_SERIAL_NUMBER':
      return update(state, {
        ui: {
          pricing: {
            heavy_duty_engine_serial_number: {
              $set: action.heavy_duty_engine_serial_number,
            },
          },
        },
      });

    case 'SELECT_HEAVY_DUTY_VIN':
      return update(state, {
        ui: {
          pricing: {
            vin: {
              $set: action.vin,
            },
          },
        },
      });

    case 'SELECT_HEAVY_DUTY_FUEL_TYPE':
      return update(state, {
        ui: {
          pricing: {
            fuel_type: {
              $set: action.fuel_type,
            },
          },
        },
      });

    case 'SELECT_HEAVY_DUTY_OTHER_FUEL_TYPE':
      return update(state, {
        ui: {
          pricing: {
            other_fuel_type: {
              $set: action.other_fuel_type,
            },
          },
        },
      });

    case 'SELECT_OTHER_MAKE':
      return update(state, {
        ui: {
          pricing: {
            other_make: { $set: action.otherMakeName },
            $unset: ['vehicle_id', 'color'],
          },
        },
      });

    case 'SELECT_HEAVY_DUTY_OTHER_ENGINE_MAKE':
      return update(state, {
        ui: {
          pricing: {
            heavy_duty_other_engine_make: {
              $set: action.heavy_duty_other_engine_make,
            },
            $unset: ['vehicle_id', 'color'],
          },
        },
      });

    case 'SET_DIAGNOSTIC':
      return update(state, {
        ui: {
          diagnostic: {
            $set: action.diagnostic,
          },
        },
      });

    case 'SET_DETAILING_NOTE':
      return update(state, {
        ui: {
          detailingNote: { $set: action.detailingNote },
        },
      });

    case 'CLEAR_TIRE_DETAILS':
      return update(state, {
        ui: {
          tireDetails: {
            $set: {},
          },
          tireNotes: {
            $set: {},
          },
        },
      });

    case 'SET_TIRE_NOTE':
      return update(state, {
        ui: {
          tireNotes: { [action.key]: { $set: action.tireNote } },
        },
      });

    case 'SET_TIRE_ONRIMS':
      return update(state, {
        ui: {
          tireDetails: {
            onRims: { $set: action.onRims },
          },
        },
      });

    case 'SET_TIRE_SIZE':
      return update(state, {
        ui: {
          tireDetails: {
            tireSize: { $set: action.tireSize },
            rawTireSize: { $set: action.rawTireSize },
          },
        },
      });

    case 'SET_TIRE_PRODUCTS':
      return update(state, {
        ui: {
          tireProducts: {
            [action.key]: {
              $set: action.tireProducts,
            },
          },
        },
      });

    case 'SET_RECALL_LIST':
      return update(state, {
        ui: { recallList: { $set: action.recallList } },
      });

    case 'SET_PRICE_LIST':
      return update(state, {
        ui: { priceList: { $set: action.priceList } },
      });

    case 'SET_TAX_RATE':
      return update(state, {
        ui: {
          taxRate: { $set: action.taxRate },
        },
      });

    case 'SET_COUPON':
      return update(state, {
        ui: {
          coupon: {
            $set: action.coupon,
          },
        },
      });

    case 'SET_COUPON_LIST':
      return update(state, {
        ui: {
          couponList: {
            $set: action.couponList,
          },
        },
      });

    case 'SET_CUSTOM_DISCOUNT':
      return update(state, {
        ui: {
          customDiscount: {
            $set: action.customDiscount,
          },
        },
      });

    case 'ADD_MESSAGE':
      const msg = {
        ...action,
      };
      return update(state, {
        ui: {
          messages: {
            [action.message]: { $set: msg },
          },
        },
      });

    case 'REMOVE_MESSAGE':
      return update(state, {
        ui: { messages: { $unset: [action.message] } },
      });

    case 'SET_CART':
      return update(state, {
        ui: { cart: { $set: action.cart.filter((s) => s) } },
      });
    case 'SET_AUTOSELECT_SERVICE_OFFERING_IDS':
      return update(state, {
        ui: {
          autoSelectServiceOfferingIds: {
            $set: action.autoSelectServiceOfferingIds,
          },
        },
      });
    case 'SET_ACCOUNT':
      return update(state, { ui: { account: { $set: action.account } } });
    case 'SET_VEHICLES':
      return update(state, { ui: { vehicles: { $set: action.vehicles } } });
    case 'SET_SCHEDULED_WORKS':
      return update(state, {
        ui: { scheduledWorks: { $set: action.scheduledWorks } },
      });

    case 'CLEAR_PRICING':
      return update(state, {
        ui: { pricing: { $set: {} } },
      });

    case 'SELECT_VEHICLE': {
      return update(state, {
        ui: {
          pricing: { $set: action.vehicle },
        },
      });
    }

    case 'SET_SCHEDULE':
      return update(state, {
        ui: { schedule: { $set: action.schedule } },
      });

    case 'SET_SCHEDULE_DATE':
      return update(state, {
        ui: { schedule: { date: { $set: action.date } } },
      });

    case 'CLEAR_USER': {
      // Keep clientLocations and z3pConfiguration since they are not user-specific
      const { clientLocations, z3pConfiguration } = state.ui;
      const newState = INITIAL_STATE;
      newState.ui.clientLocations = clientLocations;
      newState.ui.z3pConfiguration = z3pConfiguration;
      return newState;
    }

    case 'SET_CLIENT_LOCATIONS':
      return update(state, {
        ui: { clientLocations: { $set: action.clientLocations } },
      });

    case 'SET_KEYOSK':
      return update(state, {
        ui: { keyosk: { $set: action.keyosk } },
      });

    case 'SET_PARKING':
      return update(state, {
        ui: { parking: { $set: action.parking } },
      });

    case 'SET_DEDICATED_PARKING':
      return update(state, {
        ui: { dedicatedParking: { $set: action.dedicatedParking } },
      });

    case 'SET_AVAILABLE_SCHEDULE_TYPES':
      return update(state, {
        ui: { availableScheduleTypes: { $set: action.availableScheduleTypes } },
      });

    case 'SET_COMPATIBLE_DATES':
      return update(state, {
        ui: { compatibleDates: { $set: action.compatibleDates } },
      });

    case 'SET_CHANGE_BOOKING':
      return update(state, {
        ui: { changeBooking: { $set: action.changeBooking } },
      });

    case 'SET_SERVICE_AREAS':
      return update(state, {
        ui: { serviceAreas: { $set: action.serviceAreas } },
      });

    case 'SET_ACTIVE_PAYMENT_METHODS':
      return update(state, {
        ui: { activePaymentMethods: { $set: action.activePaymentMethods } },
      });

    case 'SET_SELECTED_PAYMENT_METHOD':
      return update(state, {
        ui: { selectedPaymentMethod: { $set: action.selectedPaymentMethod } },
      });

    case 'SET_Z3P_CONFIGURATION':
      return update(state, {
        ui: { z3pConfiguration: { $set: action.z3pConfiguration } },
      });

    case 'SET_PRO_CONFIGURATION':
      return update(state, {
        ui: { proConfiguration: { $set: action.proConfiguration } },
      });

    case 'SET_IS_POSTAL_CODE_IN_UV':
      return update(state, {
        ui: { isPostalCodeInUV: { $set: action.isPostalCodeInUV } },
      });

    case 'SET_KEY_LOCATION':
      return update(state, {
        ui: { keyLocation: { $set: action.keyLocation } },
      });

    case 'SET_GO_BACK':
      return update(state, {
        ui: { goBack: { $set: action.goBack } },
      });

    case 'SET_QUOTE_ID':
      return update(state, {
        ui: { quoteId: { $set: action.quoteId } },
      });
    case 'SET_SERVICE_ID':
      return update(state, {
        ui: {
          urlLoadServiceId: { $set: action.urlLoadServiceId },
        },
    });

    default:
      return state;
  }
}

export function addMessage(
  dispatch,
  message,
  timeoutSeconds = 15,
  extraOptions = {
    messageType: 'success',
    pageToDisplayOn: null,
  },
) {
  const expirationTime = new Moment().add(timeoutSeconds, 'seconds');
  dispatch({
    type: 'ADD_MESSAGE',
    message,
    expirationTime,
    ...extraOptions,
  });
}
export default function zippity() {
  // use desired middlewares
  const middlewares = [];

  return new Promise((resolve, reject) => {
    try {
      const store = createStore(
        reducers,
        undefined,
        compose(autoRehydrate(), applyMiddleware(...middlewares)),
      );

      persistStore(store, { blacklist: ['data'] }, () => {
        resolve(store);
      });
    } catch (e) {
      reject(e);
    }
  });
}

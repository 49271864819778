// src/components/About/index.js
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Col, Row } from 'react-materialize';
import Pricing from './components/Pricing';
import {
  setPageTitle,
  setUserWorkplaceAndRedirect,
  shouldAutoSelectLocation,
} from '../helper';
import { getVehicleType } from '../vehicleHelper';

function copyFromPricingToVehicle(pricing, vehicles) {
  const newVehicle = { ...pricing };
  if (!(Object.values(vehicles).indexOf(null) > -1)) {
    let exists = false;
    exists = vehicles.find((vehicle) => {
      if (_.isEqual(vehicle, newVehicle)) {
        return true;
      }
      return false;
    });
    if (
      !exists &&
      newVehicle.year &&
      newVehicle.make &&
      newVehicle.model &&
      newVehicle.color
    ) {
      vehicles.push(newVehicle);
    }
  }
  return vehicles;
}

class Vehicle extends Component {
  state = {};

  componentWillMount() {
    const { z3pConfiguration } = this.props;
    const { is_skipping_vehicle } = z3pConfiguration;

    setPageTitle('Select Vehicle');
    if (is_skipping_vehicle) {
      browserHistory.push('/pricing/services');
    }
  }

  addNew = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'CLEAR_PRICING' });

    browserHistory.push('/pricing/vehicle-lookup');
  };

  selectVehicle = (vehicle) => {
    const { dispatch, clientLocations, z3pConfiguration, pricing } = this.props;
    const {
      service_locations: serviceLocations,
      skip_simple_services_to,
      is_skipping_vehicle,
    } = z3pConfiguration;

    const vehicleType = getVehicleType(vehicle);
    dispatch({ type: 'SELECT_VEHICLE', vehicle });
    dispatch({ type: 'SELECT_VEHICLE_TYPE', vehicleType });

    // If the only service location is workplace or shop and there is only 1 client location or a location has already been selected, skip /place
    if (shouldAutoSelectLocation(serviceLocations, clientLocations)) {
      const clId = clientLocations[0].client_location_id;
      return setUserWorkplaceAndRedirect(
        clId,
        dispatch,
        skip_simple_services_to,
        is_skipping_vehicle,
        pricing,
      );
    }
    return browserHistory.push('/pricing/place');
  };

  render() {
    const { vehicles, pricing } = this.props;
    const tempVehicles = copyFromPricingToVehicle(pricing, vehicles);

    const divs = tempVehicles.map((v, i) => (
      <Col s={12} m={6} l={4} key={i}>
        <span
          onClick={() => this.selectVehicle(v)}
          className="waves-effect waves-light btn btn-large vehicle_btn"
          role="button"
          tabIndex="-1"
          onKeyPress={this.handleKeyPress}
        >
          {v.color} {v.year} {v.make} {v.model}
        </span>
      </Col>
    ));

    return (
      <Pricing
        currentStep="Vehicle"
        h1="Select Your Vehicle"
        h2="What vehicle are you scheduling services for?"
      >
        <div className="row" id="vehicle_box">
          {divs}
        </div>
        <div className="row">
          <Col>
            <span
              onClick={() => this.addNew()}
              className="waves-effect waves-light btn-large btn"
            >
              Add a New Vehicle
            </span>
          </Col>
        </div>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    vehicles: state.ui.vehicles,
    pricing: state.ui.pricing,
    clientLocations: state.ui.clientLocations,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Vehicle);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-materialize';
import { browserHistory } from 'react-router';
import Pricing from '../components/Pricing';
import { transformEngine } from './YearMakeModelTab';
import {
  AUTOMOBILE_TABS,
  createVehicle,
  isNonAutomobileType,
  TAB_TO_TYPE,
} from '../../vehicleHelper';
import {
  stateAbbreviationToName,
  stateNameToAbbreviation,
} from '../components/UsStates';
import { setPageTitle, tracRudderStackkEvent } from '../../helper';
import { addMessage } from '../../zippity';
import { hasAccessToken } from '../../brainApi';
import NonAutomobileVehicleEntry from './NonAutomobileVehicleEntry';
import VehicleTypeSelection from './VehicleTypeSelection';
import AutomobileVehicleEntry from './AutomobileVehicleEntry';

const VehicleEntry = (props) => {
  setPageTitle('Vehicle Entry');
  const {
    dispatch,
    account,
    vehicle,
    vehicles,
    customerLocation,
    homeAddress,
    z3pConfiguration,
  } = props;

  const {
    default_state: serviceProviderDefaultState,
    default_vehicle_entry_tab: defaultTab,
    available_vehicle_entry_tabs: availableTabs,
    z3p_client_name: z3pClient,
    is_skipping_vehicle,
    is_using_heavy_duty: isUsingHeavyDuty,
  } = z3pConfiguration;
  const isValvolineHeavyDuty =
    z3pClient.toLowerCase().includes('valvoline') && isUsingHeavyDuty;

  let usState =
    homeAddress &&
    homeAddress.address &&
    homeAddress.address.state &&
    stateAbbreviationToName(homeAddress.address.state);
  if (!usState)
    usState =
      customerLocation !== null && customerLocation.customerUsState !== null
        ? customerLocation.customerUsState
        : serviceProviderDefaultState;

  let initialTab = '';
  if (isValvolineHeavyDuty) {
    initialTab = 'heavy';
  } else {
    // Tabs are only rendered for automobile vehicle types.
    // Choose default tab from z3p config if valid
    // Else choose first automobile in availableTabs
    initialTab =
      AUTOMOBILE_TABS.find(
        (tab) => tab === defaultTab && availableTabs.includes(defaultTab),
      ) || AUTOMOBILE_TABS.find((tab) => availableTabs.includes(tab));
  }

  const [activeTab, setActiveTab] = useState(initialTab);
  const [licensePlate, setLicensePlate] = useState('');
  const [vin, setVin] = useState('');
  const [selectedState, setSelectedState] = useState(
    usState || serviceProviderDefaultState,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialYmmDetails = {
    year: '',
    makeName: '',
    makeId: '',
    otherMake: '',
    modelName: '',
    modelId: '',
    otherModel: '',
    vehicleId: '',
    baseVehicleId: '',
    submodelId: '',
    submodelName: '',
    engine: transformEngine({}),
    color: '',
  };
  const [ymmVehicleDetails, setYmmVehicleDetails] = useState(initialYmmDetails);

  const isNonAutomobile = isNonAutomobileType(vehicle?.vehicleType);

  const clearVehicleState = () => {
    dispatch({ type: 'SELECT_YEAR', year: '' });
    dispatch({ type: 'SELECT_MAKE', makeName: '', makeId: '' });
    dispatch({
      type: 'SELECT_OTHER_MAKE',
      otherMakeName: '',
    });
    dispatch({ type: 'SELECT_MODEL', modelName: '', modelId: '' });
    dispatch({ type: 'SELECT_COLOR', color: '' });
    dispatch({
      type: 'SELECT_HEAVY_DUTY_ENGINE_MAKE',
      heavy_duty_engine_make: '',
    });
    dispatch({
      type: 'SELECT_HEAVY_DUTY_OTHER_ENGINE_MAKE',
      heavy_duty_other_engine_make: '',
    });
    dispatch({
      type: 'SELECT_HEAVY_DUTY_ENGINE_SERIAL_NUMBER',
      heavy_duty_engine_serial_number: '',
    });
    dispatch({
      type: 'SELECT_HEAVY_DUTY_VIN',
      vin: '',
    });
    dispatch({
      type: 'SELECT_HEAVY_DUTY_FUEL_TYPE',
      fuel_type: '',
    });
    dispatch({
      type: 'SELECT_HEAVY_DUTY_OTHER_FUEL_TYPE',
      other_fuel_type: '',
    });
  };

  const offersMultipleVehicleTypes = () => {
    const automobileOnly = availableTabs.every((tab) =>
      AUTOMOBILE_TABS.includes(tab),
    );
    return !(availableTabs.length < 2 || automobileOnly);
  };

  useEffect(() => {
    const { location } = props;
    const { query } = location;
    const { error } = query;
    if (is_skipping_vehicle) {
      browserHistory.push(`/pricing/services`);
    }
    if (query && error && error === 'true') {
      addMessage(
        dispatch,
        'There was an error looking up your vehicle. Please try again.',
        5,
        { messageType: 'error' },
      );
    }
    dispatch({ type: 'CLEAR_PRICING' });
    // If Pro only services one vehicle type, set it as vehicle type
    if (!offersMultipleVehicleTypes()) {
      dispatch({
        type: 'SELECT_VEHICLE_TYPE',
        vehicleType: TAB_TO_TYPE[availableTabs[0]],
      });
    }
  }, []);

  const handleSelectTab = (tab) => {
    clearVehicleState();
    setYmmVehicleDetails(initialYmmDetails);
    setActiveTab(tab);
  };

  const handleContinue = () => {
    const customerId = hasAccessToken() && account ? account.customer_id : null;

    tracRudderStackkEvent('Service_Vehicle_Info', {
        form_name: "Vehicle Details",
        form_type: activeTab,
        vehicle_year: ymmVehicleDetails.year,
        vehicle_make: ymmVehicleDetails.makeName,
        vehicle_modal: ymmVehicleDetails.modelName,
        vehicle_trim: ymmVehicleDetails.submodelName,
        vehicle_color: ymmVehicleDetails.color,
    });
    
    if (isNonAutomobile) {
      const vehicleData = {
        year: ymmVehicleDetails.year,
        make:
          ymmVehicleDetails.makeName === 'Other'
            ? ymmVehicleDetails.otherMake
            : ymmVehicleDetails.makeName,
        model:
          ymmVehicleDetails.modelName === 'Other'
            ? ymmVehicleDetails.otherModel
            : ymmVehicleDetails.modelName,
        color: ymmVehicleDetails.color,
        input_method_type: vehicle.vehicleType,
      };

      // Save the vehicle -- regardless of whether or not the customer already has an account
      setIsSubmitting(true);
      createVehicle(vehicleData, customerId, dispatch, vehicles).then(() => {
        setIsSubmitting(false);
        return browserHistory.push('/pricing/place');
      });
    }
    if (activeTab === 'heavy') {
      const vehicleInfo = { ...vehicle };

      if (vehicleInfo.make === 'Other') {
        vehicleInfo.make = vehicleInfo.other_make;
      }

      if (vehicleInfo.heavy_duty_engine_make === 'Other') {
        vehicleInfo.heavy_duty_engine_make =
          vehicleInfo.heavy_duty_other_engine_make;
      }

      if (vehicleInfo.fuel_type === 'Other') {
        vehicleInfo.fuel_type = vehicleInfo.other_fuel_type;
      }

      const vehicleData = {
        ...vehicleInfo,
        color: null,
        skip_motor_lookup: true,
        input_method_type: 'heavy',
      };

      setIsSubmitting(true);
      createVehicle(vehicleData, customerId, dispatch, vehicles).then(() => {
        setIsSubmitting(false);
        browserHistory.push('/pricing/place');
      });
    }
    if (activeTab === 'license') {
      return browserHistory.push(
        `/pricing/verify-vehicle-info/${stateNameToAbbreviation(
          selectedState,
        )}/${licensePlate}`,
      );
    }
    if (activeTab === 'year') {
      let engineDetails = ymmVehicleDetails.engine;
      if (engineDetails.motor_engine_id === 'do-not-know') {
        engineDetails = { engine_name: null };
      }
      const vehicleData = {
        year: ymmVehicleDetails.year,
        make: ymmVehicleDetails.makeName,
        model: ymmVehicleDetails.modelName,
        trim: ymmVehicleDetails.submodelName,
        color: ymmVehicleDetails.color,
        motor_vehicle_id: ymmVehicleDetails.vehicleId,
        motor_base_vehicle_id: ymmVehicleDetails.baseVehicleId,
        input_method_type: 'year',
        ...engineDetails,
      };

      // Save the vehicle -- regardless of whether or not the customer already has an account
      setIsSubmitting(true);
      createVehicle(vehicleData, customerId, dispatch, vehicles).then(() => {
        setIsSubmitting(false);
        browserHistory.push('/pricing/place');
      });
    }
    if (activeTab === 'vin') {
      return browserHistory.push(`/pricing/verify-vehicle-info/VIN/${vin}`);
    }
  };

  const disableContinueButton = () => {
    if (isSubmitting) {
      return true;
    }
    if (isNonAutomobile) {
      return (
        !ymmVehicleDetails.makeName ||
        (ymmVehicleDetails.makeName === 'Other' && !ymmVehicleDetails.otherMake)
      );
    }
    if (activeTab === 'license') {
      return !licensePlate || !selectedState || licensePlate.length < 3;
    }
    if (activeTab === 'year') {
      return (
        !ymmVehicleDetails.year ||
        !ymmVehicleDetails.makeId ||
        !ymmVehicleDetails.modelId
      );
    }
    if (activeTab === 'vin') {
      return !vin || vin.length !== 17;
    }
    if (activeTab === 'heavy') {
      if (!vehicle.make) {
        return true;
      }

      if (vehicle.vin?.length > 0) {
        // Validate Vin
        if (!/^[A-Za-z0-9]{17}$/.test(vehicle.vin)) {
          return true;
        }
      }

      if (vehicle.make === 'Other' && !vehicle.other_make) {
        return true;
      }

      if (
        vehicle.heavy_duty_engine_make === 'Other' &&
        !vehicle.heavy_duty_other_engine_make
      ) {
        return true;
      }

      if (vehicle.fuel_type === 'Other' && !vehicle.other_fuel_type) {
        return true;
      }

      return false;
    }
  };

  // If pro offers services to multiple vehicle types, first display a list of buttons so customer can choose a vehicle type
  if (offersMultipleVehicleTypes() && !vehicle.vehicleType) {
    return (
      <VehicleTypeSelection
        availableTabs={availableTabs}
        setActiveTab={setActiveTab}
      />
    );
  }

  return (
    <Pricing
      currentStep="Vehicle"
      h1="Tell us about your vehicle."
      h2="Enter your vehicle information"
      backText="Back to Address"
    >
      {vehicle.vehicleType === 'automobile' && (
        <AutomobileVehicleEntry
          activeTab={activeTab}
          licensePlate={licensePlate}
          setLicensePlate={setLicensePlate}
          vin={vin}
          setVin={setVin}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          ymmVehicleDetails={ymmVehicleDetails}
          setYmmVehicleDetails={setYmmVehicleDetails}
          handleSelectTab={handleSelectTab}
        />
      )}
      {vehicle.vehicleType && vehicle.vehicleType !== 'automobile' && (
        <NonAutomobileVehicleEntry
          vehicleDetails={ymmVehicleDetails}
          setVehicleDetails={setYmmVehicleDetails}
        />
      )}
      <Row>
        <Col>
          <span
            className="btn btn-large"
            onClick={handleContinue}
            disabled={disableContinueButton()}
          >
            {isSubmitting ? 'Saving...' : 'Continue'}
        </span>
        </Col>
      </Row>
    </Pricing>
  );
};

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    customerLocation: state.ui.customerLocation,
    homeAddress: state.ui.homeAddress,
    vehicle: state.ui.pricing,
    vehicles: state.ui.vehicles,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(VehicleEntry);

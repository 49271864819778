import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-materialize';
import { browserHistory } from 'react-router';

import Pricing from './components/Pricing';
import { loadClientLocationDetailsAndPriceList } from '../brain2';
import { hasAccessToken, loadAtdProducts } from '../brainApi';
import { getServiceById, getServicesByScheduleTypes } from '../serviceHelpers';
import TireServiceCard from './TireServiceCard';
import { formatPrice, setPageTitle, tracRudderStackkEvent } from '../helper';

export function getTireSize(vehicle) {
  const { standard_tire_sizes } = vehicle;

  if (standard_tire_sizes && standard_tire_sizes.length === 1) {
    return standard_tire_sizes[0].replace(/[^0-9]/gi, '');
  }

  return null;
}

class Tires extends Component {
  constructor(props) {
    super(props);
    const { priceList, pricing, z3pConfiguration } = this.props;

    this.setupPriceList = this.setupPriceList.bind(this);
    this.handleSelectTireService = this.handleSelectTireService.bind(this);
    this.handleContinue = this.handleContinue.bind(this);

    const { includedServices } = this.setupPriceList(priceList);
    // props.dispatch({ type: 'SET_CART', cart: includedServices });

    this.tireSize = getTireSize(pricing);
    // If there's only one tire size, pre-load the ATD products
    if (this.tireSize) {
      props.dispatch({ type: 'SET_TIRE_SIZE', tireSize: this.tireSize });

      const { atd_location_id } = z3pConfiguration;
      // Default to East Taunton MA ATD location, if customer has not set their location.
      const atdLocationId = atd_location_id || 1258934;
      const key = `${atdLocationId}|${this.tireSize}`;
      loadAtdProducts(atdLocationId, this.tireSize).then(({ products }) => {
        props.dispatch({
          type: 'SET_TIRE_PRODUCTS',
          key,
          tireProducts: products,
        });
      });
    }
    this.state = {
      displayAlert: false,
    };
  }

  componentWillMount() {
    setPageTitle('Select Tire Services');
  }

  componentDidMount() {
    const { dispatch, customerLocation, vehicle } = this.props;

    loadClientLocationDetailsAndPriceList(
      dispatch,
      customerLocation?.clientLocationId,
      vehicle.vehicle_id,
    );
  }

  setupPriceList(priceList) {
    this.tireServices = getServicesByScheduleTypes(priceList, ['tires']);

    // filtered based on not wanting to display Tire Disposal service
    // TODO: This filter is kind of weird because it prevents customers from
    // seeing any "addon" tire services, even if they are bookable.
    // In the future, we may want to remove this -- we would just have to check
    // with the Pros who currently have these addon tire services, and decide
    // whether to make them bookable or unbookable.
    // Also, we don't allow the ATD tire product to be shown here.
    // The "Tire Installation" service is the one that we show in the list.
    this.filteredTireServices = this.tireServices.filter(
      (a) => a.display_category !== 'addon' && !a.is_atd_tire_product,
    );

    this.filteredTireServices.sort((a, b) => {
      if (a.display_order < b.display_order) {
        return -1;
      }
      return 1;
    });

    // Some services are included by default.  Add them to the cart.
    const includedServices = this.tireServices.filter(
      (a) => !!a.included_with_every_mechanical_service,
    );

    return { includedServices };
  }

  handleSelectTireService(serviceOffering) {
    // Don't allow user to deselect these services
    if (serviceOffering.included_with_every_mechanical_service) {
      return;
    }

    const { dispatch, cart } = this.props;
    const isSelected = !!getServiceById(
      cart,
      serviceOffering.service_offering_id,
    );

    if (isSelected) {
      const servicesCopy = cart.filter(
        (s) => s.service_offering_id !== serviceOffering.service_offering_id,
      );
      dispatch({ type: 'SET_CART', cart: servicesCopy });
    } else {
      const incompat = serviceOffering.incompatible_service_offering_ids || [];
      const servicesCopy = cart.filter(
        (s) => !incompat.includes(s.service_offering_id),
      );

      if (serviceOffering.per_tire) {
        for (let i = 0; i < 4; i += 1) {
          servicesCopy.push(serviceOffering);
        }
      } else {
        servicesCopy.push(serviceOffering);
      }

      dispatch({ type: 'SET_CART', cart: servicesCopy });
    }
  }

  handleContinue() {
    const { cart } = this.props;
    
    const serviceExists = value => cart.some(c =>
        c.short_name.includes(value)
    );

    if (cart.some((x) => x.is_tire_installation_fee || x.is_atd_tire_product)) {
      return browserHistory.push('/pricing/tiresize');
    }
    tracRudderStackkEvent('Services_Requested', {
        form_name: "Service Selection",
        form_type: cart[0].display_category,
        "Tire Rotation":(serviceExists('Tire Rotation') == true) ? 'Y' : 'N',
        Inspection:(serviceExists('Inspection') == true) ? 'Y' : 'N',
        Installation: (serviceExists('Installation') == true) ? 'Y' : 'N', 

    });
    // If the user is logged in, we can skip the "Account Creation" page.
    if (hasAccessToken()) {
      return browserHistory.push('/pricing/schedule');
    }

    return browserHistory.push('/pricing/account');
  }

  displayAlert = () => {
    this.setState({ displayAlert: true });
  };

  render() {
    const { dispatch, priceList, cart, urlLoadServiceId } = this.props;
    this.setupPriceList(priceList);
    
    if(cart.length == 0 && urlLoadServiceId != ''){
      
        var selectedService = getServiceById(this.filteredTireServices, parseInt(urlLoadServiceId));

        if(selectedService){
          const incompat = selectedService.incompatible_service_offering_ids || [];
          let servicesCopy = cart.filter(
            (s) => !incompat.includes(s.service_offering_id),
          );

          if (selectedService.per_tire) {
            for (let i = 0; i < 4; i += 1) {
              servicesCopy.push(selectedService);
            }
          } else {
            servicesCopy.push(selectedService);
          }

          dispatch({ type: 'SET_CART', cart: servicesCopy });
          dispatch({ type: 'SET_SERVICE_ID', urlLoadServiceId: '' });
        }
    }
    else if(urlLoadServiceId != '' && cart.length > 0){
        var selectedService = getServiceById(this.filteredTireServices, parseInt(urlLoadServiceId));
        if(selectedService){
          const isSelected = !!getServiceById(
            cart,
            selectedService.service_offering_id,
          );

          if(!isSelected){
            const servicesCopy = this.filteredTireServices.filter(
              (s) => s.service_offering_id == selectedService.service_offering_id,
            );
            let servicesCopy2 = servicesCopy.concat(cart);
          dispatch({ type: 'SET_CART', cart: servicesCopy2 });
          dispatch({ type: 'SET_SERVICE_ID', urlLoadServiceId: '' });
        }
      }
    }

    const hasBasicService = cart.some(
      (s) => s && !s.included_with_every_mechanical_service,
    );

    const buttonClass = `btn button-link btn-large${
      hasBasicService ? '' : ' disabled-button-link'
    }`;
    const cartTotalDollars = cart
      .map((s) => parseFloat(s.total_cost_dollars) * (s.quantity || 1))
      .reduce((sum, x) => sum + x, 0);

    const { displayAlert } = this.state;
    return (
      <Pricing
        currentStep="Services"
        h1="Select Your Services"
        showContinue
        enableContinue={hasBasicService}
        onContinue={this.handleContinue}
        backText="Back To vehicle"
      >
        <Row className="tire-row">
          {this.filteredTireServices.map((serviceOffering) => (
            <TireServiceCard
              key={serviceOffering.short_name}
              serviceOffering={serviceOffering}
              handleSelectTireService={this.handleSelectTireService}
            />
          ))}
        </Row>
        <Row className="tire-total">
          <Col className="services-content">
              <ul>
                <li className="total">
                    <span className="neue">
                      Total before tax:
                    </span>
                    <span className="header">{formatPrice(cartTotalDollars)}</span>
                </li>
              </ul>
          </Col>
        </Row>
        <Row>
          <Col>
              <button
                type="button"
                className={buttonClass}
                onClick={hasBasicService ? this.handleContinue : this.displayAlert}
              >
                Continue
              </button>
          </Col>
        </Row>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    vehicle: state.ui.pricing,
    account: state.ui.account,
    priceList: state.ui.priceList,
    cart: state.ui.cart,
    pricing: state.ui.pricing,
    customerLocation: state.ui.customerLocation,
    z3pConfiguration: state.ui.z3pConfiguration,
    urlLoadServiceId: state.ui.urlLoadServiceId,
  };
}

export default connect(mapStateToProps, null)(Tires);

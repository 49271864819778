/* globals window */

import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import qstring from 'query-string';
import { Button, Col, Row } from 'react-materialize';
import Moment from 'moment';

import Raven from 'raven-js';
import LocationDropdown from './components/LocationDropdown';
import Pricing from './components/Pricing';
import {
  loadAvailableScheduleTypes,
  loadClientLocationDetailsAndPriceList,
  loadCoupon,
} from '../brain2';
import { addMessage } from '../zippity';
import { apiGet, hasAccessToken } from '../brainApi';
import SetAddressForm from './components/SetAddressForm';
import { isFromQuote, shouldAutoSelectLocation, Z3P_CONFIG, tracRudderStackkEvent, currentPageInfo, getChannel } from '../helper';
import { stateNameToAbbreviation } from './components/UsStates';
import { workplaceQuestionReason } from '../placeHelper';
import ShopLocationDropdown from './components/ShopLocationDropdown';

const Begin = (props) => {
  const {
    z3pConfiguration,
    location,
    account,
    dispatch,
    vehicles,
    vehicle,
    cart,
    clientLocations,
    homeAddress,
    customerLocation
  } = props;
  const {
    service_locations: serviceLocations,
    is_skipping_vehicle,
  } = z3pConfiguration;
  const hasShopService = serviceLocations.includes('shop');
  const hasWorkplaceService = serviceLocations.includes('workplace');
  const hasHomeService = serviceLocations.includes('home');
  const isLoggedIn = hasAccessToken();

  const [locationName, setLocationName] = useState('');
  const [clientLocationId, setClientLocationId] = useState('');
  const [address, setAddress] = useState(null);
  const [homeAddressValid, setHomeAddressValid] = useState(true);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  // Options: buttons (if multiple service locations), home, shop, workplace
  const [pageView, setPageView] = useState('');

  useEffect(() => {
    if (serviceLocations.length === 2) {
      setPageView('shop')
    }
    if (serviceLocations.length === 1) {
      setPageView(serviceLocations[0]);
    }

    if (qstring) {
      loadQueryStringParams(location.search);
      browserHistory.push('/'); // Clear query params
    }

    if (isLoggedIn) {
      browserHistory.push('/account');
    } else {
      loadClientLocations();
    }
  }, []);

  const setLocationAndContinue = (cl) => {
    const { short_name: locationShortName, client_location_id: clId } = cl;
    setLocationName(locationShortName);
    setClientLocationId(clId);

    loadAvailableScheduleTypes(clId).then((availableScheduleTypes) => {
      dispatch({
        type: 'SET_AVAILABLE_SCHEDULE_TYPES',
        availableScheduleTypes,
      });
    });

    // Auto-select client location and clear out home address
    const vehicle_id = vehicle?.vehicle_id;
    loadClientLocationDetailsAndPriceList(dispatch, clId, vehicle_id).then(
      () => {
        dispatch({
          type: 'SET_HOME_ADDRESS',
          address: {},
          addressIDSelected: null,
          addressNameSelected: null,
        });
      },
    );
    navigateToNextPage();
  };

  const loadClientLocations = () => {
    // If the only service location is workplace or shop, set loading to true to wait to render until locations are loaded
    if (
      serviceLocations.length === 1 &&
      serviceLocations[0] !== 'home' &&
      !clientLocations.length
    ) {
      setIsLoadingLocations(true);
    }
    if (hasWorkplaceService || hasShopService) {
      const params = { filter: JSON.stringify({ is_active: true }) };
      apiGet('/client-location', params)
        .then((response) => response.json())
        .then((clientLocationsResponse) => {
          setIsLoadingLocations(false);
          dispatch({
            type: 'SET_CLIENT_LOCATIONS',
            clientLocations: clientLocationsResponse,
          });
          if (
            shouldAutoSelectLocation(serviceLocations, clientLocationsResponse)
          ) {
            setLocationAndContinue(clientLocationsResponse[0]);
          }
        })
        .catch((error) => {
          Raven.captureException(error);
        });
    }
  };

  const shouldAutoSelect =
    ['workplace', 'shop'].includes(pageView) && clientLocations.length === 1;

  useEffect(() => {
    if (shouldAutoSelect) {
      setLocationAndContinue(clientLocations[0]);
    }
  }, [pageView]);

  const setCouponCodeIfValid = (couponCode) => {
    dispatch({
      type: 'SET_COUPON',
      coupon: null,
    });
    if (!couponCode) {
      return;
    }

    const couponUpper = couponCode.toUpperCase();

    loadCoupon(couponUpper).then((coupon) => {
      const { coupon_code, end_date, valid_client_location_ids } = coupon;

      if (!coupon_code) {
        console.log(`Invalid coupon: ${couponUpper}`);
        return;
      }

      if (end_date && Moment().isAfter(end_date, 'day')) {
        console.log(`Coupon ${couponUpper} expired on ${end_date}`);
        return;
      }

      if (valid_client_location_ids && valid_client_location_ids.length > 0) {
        const { clientLocationId } = account;
        if (
          clientLocationId &&
          !valid_client_location_ids.includes(clientLocationId)
        ) {
          console.log(
            `Coupon ${couponUpper} not valid at clientLocation ${clientLocationId}`,
          );
          return;
        }
      }

      const message = `Coupon ${couponUpper} successfully added! Discount will be applied at checkout.`;

      dispatch({ type: 'SET_COUPON', coupon });
      addMessage(dispatch, message, 10);
    });
  };

  const setServiceOfferingIds = (serviceOfferingIds) => {
    if (!serviceOfferingIds || serviceOfferingIds.length === 0) {
      dispatch({
        type: 'SET_AUTOSELECT_SERVICE_OFFERING_IDS',
        autoSelectServiceOfferingIds: [],
      });
    } else {
      dispatch({
        type: 'SET_AUTOSELECT_SERVICE_OFFERING_IDS',
        autoSelectServiceOfferingIds: serviceOfferingIds,
      });

      const hasVehicle = vehicles && vehicles.length > 0;
      const message = `Services have been added to your cart! ${
        hasVehicle ? 'Choose' : 'Add'
      } your vehicle to proceed.`;

      addMessage(dispatch, message, 10);
    }
  };

  const setLocation = (clientLocationId) => {
    if (!clientLocationId) {
      return;
    }

    const vehicle_id = vehicle?.vehicle_id;
    loadClientLocationDetailsAndPriceList(
      dispatch,
      clientLocationId,
      vehicle_id,
    ).then(() => {
      loadAvailableScheduleTypes(clientLocationId).then(
        (availableScheduleTypes) => {
          dispatch({
            type: 'SET_AVAILABLE_SCHEDULE_TYPES',
            availableScheduleTypes,
          });
        },
      );

      if (is_skipping_vehicle) {
        browserHistory.push('/pricing/simple');
      } else {
        browserHistory.push('/pricing/vehicle-lookup');
      }
    });
  };

  const loadQueryStringParams = (searchString) => {
    const queryString = qstring.parse(searchString);
    if (!queryString) {
      return;
    }

    const {
      referralCode = '',
      refSource = 'direct',
      couponCode = '',
      serviceOfferingIds = '',
      clientLocationId = '',
      message = '',
    } = queryString;

    // Fetch the referralCode and refSource if the user came from
    // a friend's referral link. Note this is the *referring friend's* code.
    dispatch({
      type: 'SET_REFERRER',
      referralCode,
      refSource,
    });

    // Deep-Link for coupon code
    setCouponCodeIfValid(couponCode);

    // Deep-Link for services
    const parsedServiceOfferingIds = serviceOfferingIds
      .split(',')
      .map((s) => parseInt(s, 10))
      .filter((i) => i);
    setServiceOfferingIds(parsedServiceOfferingIds);

    // Deep-Link for Location (if user is not logged in)
    const parsedClientLocationId = parseInt(clientLocationId, 10);
    if (parsedClientLocationId && !account.customer_id) {
      setLocation(parsedClientLocationId);
    }

    if (message) {
      addMessage(dispatch, message, 10);
    }
  };

  const navigateToNextPage = () => {
    let nextPage;
    if (isFromQuote(cart)) {
      nextPage = !hasAccessToken() ? 'account' : 'schedule';
    } else if (is_skipping_vehicle) {
      nextPage = 'services';
    } else {
      nextPage = 'vehicle-lookup';
    }
    browserHistory.push(`/pricing/${nextPage}`);
    localStorage.removeItem('enteringAddressManually');
  };

  const navigateToLogin = () => {
    tracRudderStackkEvent('Zippity_Log_in', {
      description: `User clicked the login button on ${document.title} page`,
      button_name: 'Booked service before? login',
      account_type: '',
      account_id: '',
      page_information: {
        path:currentPageInfo['PATH'],
        url:currentPageInfo['URL'],
        title:document.title
      }
    });
    
    const redirect = encodeURIComponent('/pricing/place');
    browserHistory.push(
      `/login${isFromQuote(cart) ? `?redirect=${redirect}` : ''}`,
    );
  };

  const handleSubmit = () => {
    const stateName =
      address &&
      (stateNameToAbbreviation(address.state) || address.state.toUpperCase());

    if (!locationName && !address) {
      window.alert('Please choose a location.');
      return;
    }
    let enteringAddressManually = localStorage.getItem("enteringAddressManually");

    tracRudderStackkEvent('Address_Filled', {
        path: currentPageInfo['PATH'],
        url: currentPageInfo['URL'],
        title: (currentPageInfo['TITLE']) ? currentPageInfo['TITLE'] : Z3P_CONFIG.customer_facing_name,
        referrer: document.referrer,
        addressSelect:address.address_line_1,
        city: address.city,
        state: address.state,
        postal_code: address.postal_code,
        country: address.country,
        method: (enteringAddressManually) ? 'Manual' : 'Automated',
    });

    const vehicle_id = vehicle?.vehicle_id;
    if (address) {
      dispatch({
        type: 'SET_HOME_ADDRESS',
        address: address && {
          ...address,
          state: stateName,
          is_deleted: false,
          is_primary: false,
        },
        addressNameSelected:
          address && (address.display_name || address.address_line_1),
      });
      // Load the service offerings and prices
      loadClientLocationDetailsAndPriceList(dispatch, null, vehicle_id);
      navigateToNextPage();
    } else {
      loadAvailableScheduleTypes(clientLocationId).then(
        (availableScheduleTypes) => {
          dispatch({
            type: 'SET_AVAILABLE_SCHEDULE_TYPES',
            availableScheduleTypes,
          });
        },
      );

      loadClientLocationDetailsAndPriceList(
        dispatch,
        clientLocationId,
        vehicle_id,
      ).then(() => {
        navigateToNextPage();
      });
    }
  };

  const handleClientChange = (event) => {
    const clientLocationId = event.target[
      event.target.selectedIndex
    ].getAttribute('data-client-location-id');

    setLocationName(event.target.value);
    setClientLocationId(clientLocationId);
  };

  const selectedWorkplaceNotAvailable =
    hasWorkplaceService && location === 'other';

  const h2Text = () => {
    if (pageView === 'buttons') {
      return 'Where would you like to get services?';
    }
    if (pageView === 'home') {
      return 'Please enter your address';
    }
    if (pageView === 'workplace') {
      return 'Please select your workplace';
    }
    if (pageView === 'shop') {
      return 'Please select your shop';
    }
  };

  // Without this return, these 3 cases cause the screen to flash before loading
  // If loading locations, wait to render the page until they are loaded and the dropdown is ready
  // If the user is logged in, do not render this page - redirect to My Acount
  // If the Pro only offers shop/workplace and there is a single location, do not render and skip to vehicle entry
  if (isLoadingLocations || isLoggedIn || shouldAutoSelect) {
    return null;
  }

  return (
    <Pricing
      currentStep="Address"
      h1="Let's Get Started"
      h2={h2Text()}
      hideAlert
    >
      {pageView === 'buttons' && (
        <Row>
          <Col className="get-started-form">
            <Button
              onClick={() => setPageView('home')}
              className="btn-large header"
            >
              At Home
            </Button>
          </Col>

          {hasWorkplaceService && (
            <Col className="get-started-form">
              <Button
                onClick={() => setPageView('workplace')}
                className="btn-large header"
              >
                At Work
              </Button>
            </Col>
          )}

          {hasShopService && (
            <Col className="get-started-form">
              <Button
                onClick={() => setPageView('shop')}
                className="btn-large header"
              >
                At The Shop
              </Button>
            </Col>
          )}
        </Row>
      )}

      {pageView === 'home' && (
        <div>
            <SetAddressForm
              strippedVersion
              onSetAddress={(addr) => {
                setAddress(addr);
              }}
              onSelectAddress={(addr) => {
                setAddress(addr);
              }}
              onChangeField={(addr) => {
                setAddress({ ...address, ...addr });
              }}
              onCheckAddressValidity={(validity) => {
                setHomeAddressValid(validity);
              }}
              homeAddress={homeAddress}
            />

            {hasWorkplaceService && (
              <Row>
                <Col>
                  <Button onClick={() => setPageView('workplace')}>
                    Add a workplace instead
                  </Button>
                </Col>
              </Row>
            )}
        </div>
      )}

      {pageView === 'workplace' && (
        <div>
          {workplaceQuestionReason()}
          <div>
            <Row>
              <LocationDropdown
                handleClientChange={handleClientChange}
                selectedClientLocation={locationName}
              />
            </Row>

            {hasHomeService && (
              <Row>
                <Col>
                  <Button onClick={() => setPageView('home')}>
                    Add a home address instead
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}

      {pageView === 'shop' && (
        <div>
          <Row>
            <ShopLocationDropdown
              handleClientChange={handleClientChange}
              selectedClientLocation={locationName}
              setLocationName={setLocationName}
              setClientLocationId={setClientLocationId}
              navigateToNextPage={navigateToNextPage}
            />
          </Row>

          {hasHomeService && (
            <Row>
              <Col>
                <Button onClick={() => setPageView('home')}>
                  Add a home address instead
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}

      {selectedWorkplaceNotAvailable ||
      ['buttons', ''].includes(pageView) ? null : (
        <Button
          disabled={(!locationName && !address) || !homeAddressValid}
          className="btn-large header"
          value="Continue"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      )}

      {pageView !== '' && (
        <div className="login-link-box">
          Booked service before?{' '}
          <strong>
            <a className="login-link" onClick={navigateToLogin}>
              Log In
            </a>
          </strong>
        </div>
      )}
    </Pricing>
  );
};

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    vehicles: state.ui.vehicles,
    vehicle: state.ui.pricing,
    cart: state.ui.cart,
    clientLocations: state.ui.clientLocations,
    z3pConfiguration: state.ui.z3pConfiguration,
    homeAddress: state.ui.homeAddress,
  };
}

export default connect(mapStateToProps, null)(Begin);

/* globals window */
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Card, Col, Icon } from 'react-materialize';
import _ from 'lodash';

import Raven from 'raven-js';
import Pricing from './components/Pricing';
import { addDeepLinkedServices } from '../serviceHelpers';
import { apiGet } from '../brainApi';
import {
  loadAvailableScheduleTypes,
  loadClientLocationDetailsAndPriceList,
} from '../brain2';
import { setPageTitle } from '../helper';
import { isNonAutomobileType } from '../vehicleHelper';

class SimpleServices extends Component {
  constructor(props) {
    super(props);

    this.handleDiagnosticSelect = this.handleDiagnosticSelect.bind(this);
    this.handleExpertSelect = this.handleExpertSelect.bind(this);
    this.handleDetailingSelect = this.handleDetailingSelect.bind(this);
    this.handleTiresSelect = this.handleTiresSelect.bind(this);
  }

  componentWillMount() {
    setPageTitle('Select Service Category');
  }

  componentDidMount() {
    const {
      dispatch,
      customerLocation,
      vehicle,
      autoSelectServiceOfferingIds,
      priceList,
      homeAddress,
      z3pConfiguration,
    } = this.props;
    const {
      skip_simple_services_to,
      z3p_client_name: z3pEnv,
      is_skipping_vehicle,
    } = z3pConfiguration;

    loadClientLocationDetailsAndPriceList(
      dispatch,
      customerLocation?.clientLocationId,
      vehicle?.vehicle_id,
    );

    // If customer used a Services Deep Link, then skip ahead.
    addDeepLinkedServices(dispatch, autoSelectServiceOfferingIds, priceList);

    const skipToNonAutomobileServices = isNonAutomobileType(
      vehicle?.vehicleType,
    );
    if (skipToNonAutomobileServices || is_skipping_vehicle) {
      browserHistory.push('/pricing/services');
    } else if (
      skip_simple_services_to &&
      ['services', 'detailing', 'tires', 'diagnostic'].includes(
        skip_simple_services_to,
      )
    ) {
      browserHistory.push(`/pricing/${skip_simple_services_to}`);
    }

    const isAtHomeService = !!homeAddress.addressIDSelected;
    const locationID = isAtHomeService
      ? homeAddress.addressIDSelected
      : customerLocation.clientLocationId;

    if (locationID) {
      loadAvailableScheduleTypes(locationID, isAtHomeService).then(
        (availableScheduleTypes) => {
          dispatch({
            type: 'SET_AVAILABLE_SCHEDULE_TYPES',
            availableScheduleTypes,
          });
        },
      );
    } else {
      let availableScheduleTypes = ['mechanical', 'tires', 'detailing'];
      if (z3pEnv === 'zippity') {
        // TODO: We can delete this logic if we delete all unused schedule-types from zippity's DB.
        // https://brain2-zippity.zippity.cc/schedule-type
        availableScheduleTypes = ['mechanical', 'tires'];
        dispatch({
          type: 'SET_AVAILABLE_SCHEDULE_TYPES',
          availableScheduleTypes,
        });
      } else {
        // TODO: This is all schedule types, not just available schedule types.
        apiGet('/schedule-type')
          .then((res) => res.json())
          .then((scheduleTypes) => {
            availableScheduleTypes = scheduleTypes.map((st) => st.name);
            dispatch({
              type: 'SET_AVAILABLE_SCHEDULE_TYPES',
              availableScheduleTypes,
            });
          })
          .catch((error) => Raven.captureException(error));
      }
    }

    // Reload the client location details, including the service list
    const setLocation = !isAtHomeService;
    const vehicle_id = vehicle?.vehicle_id;
    loadClientLocationDetailsAndPriceList(
      dispatch,
      customerLocation.clientLocationId,
      vehicle_id,
      {
        setLocation,
      },
    );

    dispatch({
      type: 'SET_CHANGE_BOOKING',
      changeBooking: false,
    });

    dispatch({ type: 'SET_COMPATIBLE_DATES', compatibleDates: [] });
    dispatch({ type: 'SET_SCHEDULE', schedule: {} });

    // Push back to the beginning if there's no car info and they're not signed in
    if (!is_skipping_vehicle && _.isEmpty(vehicle)) {
      dispatch({
        type: 'SET_CART',
        cart: [],
      });
      return browserHistory.push('/pricing/begin');
    }
    return null;
  }

  handleDiagnosticSelect() {
    const { dispatch } = this.props;
    dispatch({
      type: 'SET_DIAGNOSTIC',
      diagnostic: {
        selected: true,
      },
    });
    browserHistory.push('/pricing/diagnostic');
  }

  handleExpertSelect() {
    const { dispatch } = this.props;
    // Clear out any diagnostic data if they went back and
    // rerouted to expert services instead
    dispatch({
      type: 'SET_DIAGNOSTIC',
      diagnostic: {},
    });
    browserHistory.push('/pricing/services');
  }

  handleDetailingSelect() {
    const { dispatch } = this.props;
    // Clear out any diagnostic data if they went back and
    // rerouted to detailing services instead
    dispatch({
      type: 'SET_DIAGNOSTIC',
      diagnostic: {},
    });
    browserHistory.push('/pricing/detailing');
  }

  handleTiresSelect() {
    const { dispatch } = this.props;
    // Clear out any diagnostic data if they went back and
    // rerouted to detailing services instead
    dispatch({
      type: 'SET_DIAGNOSTIC',
      diagnostic: {},
    });
    browserHistory.push('/pricing/tires');
  }

  render() {
    const { availableScheduleTypes, z3pConfiguration } = this.props;
    const { has_diagnostic_service: hasDiagnosticService } = z3pConfiguration;

    const detailingAvailable =
      availableScheduleTypes && availableScheduleTypes.includes('detailing');

    const tiresAvailable =
      availableScheduleTypes && availableScheduleTypes.includes('tires');

    let mechanicalAvailable =
      availableScheduleTypes && availableScheduleTypes.includes('mechanical');

    // If somehow nothing is available, make mechanical available.
    if (!tiresAvailable && !detailingAvailable && !mechanicalAvailable) {
      mechanicalAvailable = true;
    }

    // TODO: Remove when we start enabling fuel services to be booked
    const availableServiceTypes = availableScheduleTypes.filter(
      (serviceType) => serviceType !== 'fuel',
    );

    const numberOfServiceTypes = 1 + availableServiceTypes.length;
    // If # of service tiles is odd, then fit 3 per row. If even, then 2 per row
    // # of service tiles will always be >= 2
    const colWidth = numberOfServiceTypes % 2 > 0 ? 4 : 6;

    return (
      <Pricing currentStep="Services" h1="Select Your Services">
        {mechanicalAvailable && (
          <Col m={colWidth} s={12}>
            <Card
              className="simple-services-card expert-card"
              title="Mechanical Services"
              actions={[
                <span
                  key={0}
                  tabIndex={0}
                  role="button"
                  onKeyPress={this.handleExpertSelect}
                  onClick={this.handleExpertSelect}
                >
                  SELECT
                </span>,
              ]}
            >
              <span className="experts-subheader">
                WHEN YOU NEED MAINTENANCE
              </span>
              <p>Choose mechanical services from our menu of options.</p>
            </Card>
          </Col>
        )}

        {tiresAvailable && (
          <Col m={colWidth} s={12}>
            <Card
              className="simple-services-card expert-card"
              title="Tire Services"
              actions={[
                <span
                  key={1}
                  tabIndex={0}
                  role="button"
                  onKeyPress={this.handleTiresSelect}
                  onClick={this.handleTiresSelect}
                >
                  SELECT
                </span>,
              ]}
            >
              <span className="tires-subheader">
                WHEN YOU NEED TIRE SERVICE
              </span>
              <p>
                Purchase new tires, install seasonal tires, or maintain your
                existing tires.
              </p>
            </Card>
          </Col>
        )}

        {detailingAvailable && (
          <Col m={colWidth} s={12}>
            <Card
              className="simple-services-card expert-card"
              title="Eco-Friendly Detailing Services"
              actions={[
                <span
                  key={1}
                  tabIndex={0}
                  role="button"
                  onKeyPress={this.handleDetailingSelect}
                  onClick={this.handleDetailingSelect}
                >
                  SELECT
                </span>,
              ]}
            >
              <span className="detailing-subheader">
                WHEN YOU NEED A CLEANUP
              </span>
              <p>
                Car detailing, with none of the hassle or harmful chemicals of
                traditional detailing.
              </p>
            </Card>
          </Col>
        )}

        {mechanicalAvailable && hasDiagnosticService && (
          <Col m={colWidth} s={12}>
            <Card
              className="simple-services-card beginner-card"
              title="Diagnostic Service"
              actions={[
                <span
                  key={2}
                  tabIndex={0}
                  role="button"
                  onKeyPress={this.handleDiagnosticSelect}
                  onClick={this.handleDiagnosticSelect}
                >
                  SELECT
                </span>,
              ]}
            >
              <span className="recommended-subheader">
                WHEN YOU&apos;D LIKE SOME HELP
              </span>
              <p>
                Tell us what you&apos;re experiencing and we&apos;ll check it
                out.
              </p>
            </Card>
          </Col>
        )}
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerLocation: state.ui.customerLocation,
    vehicle: state.ui.pricing,
    availableScheduleTypes: state.ui.availableScheduleTypes,
    scheduledWorks: state.ui.scheduledWorks,
    priceList: state.ui.priceList,
    account: state.ui.account,
    autoSelectServiceOfferingIds: state.ui.autoSelectServiceOfferingIds,
    recallList: state.ui.recallList,
    homeAddress: state.ui.homeAddress,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(SimpleServices);

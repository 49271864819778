/* globals window */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-materialize';
import { browserHistory } from 'react-router';

import { hasAccessToken } from '../../brainApi';
import { getVehicleType } from '../../vehicleHelper';
import { determineIfSingleTotalColumn, priceOr, tracRudderStackkEvent, currentPageInfo } from '../../helper';

class FutureQuote extends Component {
  constructor(props) {
    super(props);

    this.handleQuoteButtonClick = this.handleQuoteButtonClick.bind(this);
    this.customerLoginMatchesSW = this.customerLoginMatchesSW.bind(this);
  }

  loginAndReturn = () => {
    
    tracRudderStackkEvent('Zippity_Log_in', {
      description: `User clicked the login button on ${document.title} page`,
      button_name: 'Log in to book these services',
      account_type: '',
      account_id: '',
      page_information: {
        path:currentPageInfo['PATH'],
        url:currentPageInfo['URL'],
        title:document.title
      }
    });

    const currentPath = window.location ? window.location.pathname : '';
    const redirect = encodeURIComponent(currentPath);
    return browserHistory.push(`/login?redirect=${redirect}`);
  };

  transformServices = (services) => {
    return services.map((s) => {
      return {
        service_offering_id: s.service_offering_id,
        short_name: s.short_name,
        long_name: s.long_name,
        parts_cost_dollars: s.parts_cost_dollars,
        labor_cost_dollars: s.labor_cost_dollars,
        total_cost_dollars: s.total_cost_dollars,
        copy_from_service_id: s.id,
        schedule_types: ['mechanical'],
        display_category: 'standard',
        scheduled_work_id: s.scheduled_work_id,
      };
    });
  };

  handleQuoteButtonClick() {
    const {
      futureUpsells,
      rejectedUpsells,
      vehicle,
      dispatch,
      keyosk,
      parking,
    } = this.props;
    if (!this.customerLoginMatchesSW()) {
      return;
    }

    const cart = this.transformServices(futureUpsells.concat(rejectedUpsells));

    dispatch({
      type: 'SET_CART',
      cart,
    });

    // Grab the vehicle from this SW, and copy vehicle.id into vehicle.vehicleId
    const updatedVehicle = vehicle
      ? {
          ...vehicle,
          vehicleId: vehicle.id,
        }
      : null;

    dispatch({ type: 'SELECT_VEHICLE', vehicle: updatedVehicle });
    if (updatedVehicle) {
      const vehicleType = getVehicleType(updatedVehicle);
      dispatch({ type: 'SELECT_VEHICLE_TYPE', vehicleType });
    }
    dispatch({ type: 'SET_SCHEDULE_DATE', date: '' });

    // Pre-select the keyosk/parking from the last appointment.
    // Note: They will still see the "Keyosk/Parking Selection Page",
    // but can just click "Continue". It could be a slightly better
    // experience if we let them skip that page entirely.
    if (keyosk && parking) {
      dispatch({ type: 'SET_KEYOSK', keyosk });
      dispatch({ type: 'SET_PARKING', parking });
    }

    // Customer is taken to the place-selection page.
    // Once they select a place, they are redirected to "mechanical services".
    const redirect = encodeURIComponent('/pricing/services');
    browserHistory.push(`/pricing/place?redirect=${redirect}`);
  }

  customerLoginMatchesSW() {
    const { account, customerId } = this.props;

    return (
      hasAccessToken() &&
      account.customer_id &&
      account.customer_id.toString() === customerId.toString()
    );
  }

  render() {
    const { futureUpsells, rejectedUpsells, vehicle } = this.props;

    const vehicleShortDescription = vehicle?.display_name;

    const isLoggedIn = this.customerLoginMatchesSW();
    const showTotalOnly = determineIfSingleTotalColumn(futureUpsells);
    const serviceWidth = !showTotalOnly ? '40%' : '80%';

    return (
      <Fragment>
        <p>To schedule these services, click the button below.</p>
        <p>
          <strong>QUOTES FOR FUTURE SERVICES</strong>
        </p>

        <div className="approve-service-table">
          <table>
            <tr>
              <th width={serviceWidth}>SERVICE</th>
              {!showTotalOnly ? (
                <>
                  <th width="20%">PARTS</th>
                  <th width="20%">LABOR</th>
                  <th width="20%">TOTAL</th>
                </>
              ) : (
                <th width="20%">COST</th>
              )}
            </tr>
            {futureUpsells.map((s) => (
              <tr>
                <td>{s.display_name || s.long_name}</td>
                {!showTotalOnly && (
                  <>
                    <td>{priceOr(s.parts_cost_dollars, '--')}</td>
                    <td>{priceOr(s.labor_cost_dollars, '--')}</td>
                  </>
                )}
                <td>{priceOr(s.total_cost_dollars, 'FREE')}</td>
              </tr>
            ))}
            {rejectedUpsells.map((s) => (
              <tr>
                <td>{s.display_name || s.long_name}</td>
                {!showTotalOnly && (
                  <>
                    <td>{priceOr(s.parts_cost_dollars, '--')}</td>
                    <td>{priceOr(s.labor_cost_dollars, '--')}</td>
                  </>
                )}
                <td>{priceOr(s.total_cost_dollars, 'FREE')}</td>
              </tr>
            ))}
          </table>
        </div>
        <div className="bookFutureServiceButton">
          {isLoggedIn ? (
            <Button large onClick={this.handleQuoteButtonClick}>
              Schedule Service
              {vehicleShortDescription &&
                ` for your ${vehicleShortDescription}`}
            </Button>
          ) : (
            <Button large onClick={this.loginAndReturn}>
              Log in to book these services
            </Button>
          )}
        </div>
        <br />
        <br />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
  };
}

export default connect(mapStateToProps, null)(FutureQuote);

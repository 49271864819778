import React, { Component, useState } from 'react';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button, Col, Icon, Modal, Row, Checkbox } from 'react-materialize';
import update from 'immutability-helper';

import Moment from 'moment';
import Pricing from './components/Pricing';
import {
  addDeepLinkedServices,
  getServiceById,
  getServiceByName,
  getServicesByScheduleTypes,
} from '../serviceHelpers';
import { loadClientLocationDetailsAndPriceList } from '../brain2';
import { hasAccessToken } from '../brainApi';
import {
  formatPrice,
  getEmailSupportLink,
  getPhoneSupportLink,
  isFromQuote,
  isIterable,
  roundToTwoDecimals,
  setPageTitle,
} from '../helper';
import CheckboxIcon from './components/Checkbox';
import { isNonAutomobileType } from '../vehicleHelper';
import Select from './components/Select';

class Services extends Component {
  constructor(props) {
    super(props);
    
    const {
      vehicle,
      priceList,
      cart = [],
      dispatch,
      autoSelectServiceOfferingIds,
      z3pConfiguration,
    } = props;
    const {
      minimum_appointment_amount_dollars,
      has_diagnostic_service: hasDiagnosticService,
    } = z3pConfiguration;

    this.quotedServices = {
      future: null,
      pending: null,
      rejected: null,
    };
    // either finished or currently in progress
    this.mostRecentScheduledWork = null;
    // these are the services from the "Receipt" or "Quote for Next Time" pages that the user clicked to schedule
    this.quotedServicesUserWants = null;
    // the scheduled work associated with the services that the user wants to schedule
    this.quotedServicesUserWantsScheduledWork = null;
    // Minimum total cost of cart to continue
    this.minimumAmount = minimum_appointment_amount_dollars;
    // set all those variables we've just defined
    this.setQuotedServices();

    this.handleDiagnosticSelect = this.handleDiagnosticSelect.bind(this);
    this.setupPriceList = this.setupPriceList.bind(this);

    this.setupPriceList(vehicle, priceList, hasDiagnosticService);

    // Create object from already-selected services, to act as a HashMap
    const servicesObj = {};

    cart.forEach((s) => {
      if (
        s.service_offering_id &&
        s.total_cost_dollars !== null &&
        s.total_cost_dollars !== undefined &&
        (s.schedule_types.includes('mechanical') ||
          s.schedule_types.includes('pickup_and_delivery')) &&
        !s.short_name.includes('Diagnostic')
      ) {
        servicesObj[s.service_offering_id] = s;
      }
    });

    this.defaultServices.forEach((s) => {
      servicesObj[s.service_offering_id] = s;
    });

    const to = Object.values(servicesObj);
    if (isFromQuote(cart)) {
      // If this is a from a quote, keep the cart as is
    } else if (
      autoSelectServiceOfferingIds &&
      autoSelectServiceOfferingIds.length > 0
    ) {
      addDeepLinkedServices(dispatch, autoSelectServiceOfferingIds, priceList);
    } else {
      dispatch({ type: 'SET_CART', cart: to });
    }

    const hasRecallInCart =
      to.filter((service) => service.short_name.includes('Recall')).length > 0;
    const cartTotal = to
      .map((s) => parseFloat(s.total_cost_dollars) * (s.quantity || 1))
      .reduce((sum, x) => sum + x, 0);
    const minimumAmountReached =
      hasRecallInCart || cartTotal >= this.minimumAmount;
    this.state = {
      services: to,
      hasBasicService: false,
      minimumAmountReached,
      openRecallModal: false,
      parentServicesToExpand: [],
    };
  }

  componentWillMount() {
    setPageTitle('Select Service');
  }

  componentDidMount() {
    const {
      dispatch,
      vehicle,
      priceList,
      recallList,
      z3pConfiguration,
    } = this.props;
    const { services } = this.state;
    const {
      skip_simple_services_to,
      is_performing_recalls,
      is_skipping_vehicle,
    } = z3pConfiguration;
    // Push back to the beginning if there's no car info and they're not signed in
    if (!is_skipping_vehicle && _.isEmpty(vehicle)) {
      dispatch({
        type: 'SET_CART',
        cart: [],
      });
      return browserHistory.push('/pricing/begin');
    }

    // Push back to simple services page if they try navigating directly
    // and there's no priceList
    const isNonAutomobile = isNonAutomobileType(vehicle?.vehicleType);
    if (
      _.isEmpty(this.availableServices) &&
      !skip_simple_services_to &&
      !is_skipping_vehicle &&
      !isNonAutomobile
    ) {
      dispatch({
        type: 'SET_CART',
        cart: [],
      });
      return browserHistory.push('/pricing/simple');
    }

    if (is_performing_recalls) {
      // Add recall list to the cart by default if they have a recall on their vehicle
      // Check to see if the service has already been added to the cart
      const recallService = getServiceByName(services, 'Recall');
      // If the service hasn't been added and there is a recall on the vehicle, automatically add it to the cart
      if (recallList.length > 0) {
        this.setState({ openRecallModal: true });
        if (!recallService) {
          const recallServiceToAdd = getServiceByName(priceList, 'Recall');
          if (recallServiceToAdd) {
            this.selectService(recallServiceToAdd);
          }
        }
      } else if (!(recallList.length > 0) && !!recallService) {
        //  If the vehicle does not have a recall, but a recall service is in the cart, remove it
        this.selectService(recallService);
      }
    }
    this.loadData();
  }

  setupPriceList(vehicle, priceList, hasDiagnosticService) {
    const nonAutomobileTypes = ['rv', 'atv', 'motorcycle', 'plane', 'boat'];
    let filteredServices = [];
    if (nonAutomobileTypes.includes(vehicle?.vehicleType)) {
      filteredServices = getServicesByScheduleTypes(priceList, [
        vehicle?.vehicleType,
      ]);
    } else {
      filteredServices = getServicesByScheduleTypes(priceList, [
        'mechanical',
        'pickup_and_delivery',
      ]);
    }

    // Filter out short names including 'Diagnostic' from mechanical services only if Z3P client has diagnostic services
    this.availableServices = filteredServices.filter(
      (s) =>
        (!hasDiagnosticService || !s.short_name.includes('Diagnostic')) &&
        !s.short_name.includes('Recall') &&
        (!s.offered_vehicle_makes ||
          s.offered_vehicle_makes.includes(vehicle?.make)),
    );

    // Always select certain "default" services
    this.defaultServices = filteredServices.filter(
      (s) => s.included_with_every_mechanical_service,
    );
  }

  reloadData = () => {
    const { cart } = this.props;
    this.setState({ services: cart }, this.loadData);
  };

  loadData = () => {
    const {
      dispatch,
      customerLocation,
      vehicle,
      homeAddress,
      referrer,
    } = this.props;
    const { services } = this.state;

    // Expand Parent Services that are selected
    // Use set because there may be children with same parent
    const parentServicesIds = services.reduce((uniqueParentsIds, service) => {
      // Find parent corresponding to each service
      // Will return undefined if there is no parent service. This is checked below
      const parent = this.availableServices.filter(
        (s) => s.service_offering_id === service.parent_service_offering,
      )[0];

      if (parent) {
        uniqueParentsIds.add(parent.service_offering_id);
      }

      return uniqueParentsIds;
    }, new Set());

    // This is an additional check to handle a bug that we were not able to reproduce
    // Bug details: https://app.asana.com/0/1174276126132729/1189604193148141/f
    const parentServicesToExpand = isIterable(parentServicesIds)
      ? [...parentServicesIds]
      : [];

    const hasBasicService = services.some(
      (s) =>
        ['package', 'standard'].includes(s.display_category) &&
        services.filter((x) => !x.included_with_every_mechanical_service)
          .length,
    );

    this.setState({
      parentServicesToExpand,
      hasBasicService,
    });

    // If user has a referral code, take 20% off service package prices
    // Note: We allow users to stack the "20% off" referral discount plus a coupon.
    if (referrer.code) {
      const PACKAGE_MULTIPLIER = 0.8; // Multiply all costs by 80%

      this.availableServices.forEach((service) => {
        if (service.display_category === 'package') {
          service.original_value_dollars =
            service.original_value_dollars || service.total_cost_dollars;

          service.parts_cost_dollars = roundToTwoDecimals(
            service.parts_cost_dollars * PACKAGE_MULTIPLIER,
          );
          service.labor_cost_dollars = roundToTwoDecimals(
            service.labor_cost_dollars * PACKAGE_MULTIPLIER,
          );
          service.total_cost_dollars =
            service.parts_cost_dollars + service.labor_cost_dollars;
        }
      });
    }

    // Reload the client location details, including the service list
    const isAtHomeService = !!homeAddress.addressIDSelected;
    const setLocation = !isAtHomeService;
    const vehicle_id = vehicle?.vehicle_id;
    loadClientLocationDetailsAndPriceList(
      dispatch,
      customerLocation.clientLocationId,
      vehicle_id,
      {
        setLocation,
      },
    );
  };

  transformQuotedServices = (services) => {
    return services.map((s) => ({
      service_offering_id: s.service_offering_id,
      scheduled_work_id: s.scheduled_work_id,
      short_name: s.short_name,
      long_name: s.long_name,
      parts_cost_dollars: s.parts_cost_dollars,
      labor_cost_dollars: s.labor_cost_dollars,
      total_cost_dollars: s.total_cost_dollars,
      copy_from_service_id: s.id,
      schedule_types: ['mechanical'],
      display_category: 'standard',
    }));
  };

  setQuotedServices = () => {
    const { scheduledWorks, cart = [] } = this.props;
    const filteredScheduledWorks = this.getMechanicalScheduledWorks();
    const scheduledWorksCurrent = filteredScheduledWorks.filter((sw) =>
      this.scheduledWorkIsCurrent(sw),
    );
    const isScheduledWorkInProgress =
      scheduledWorksCurrent && scheduledWorksCurrent.length > 0;
    const scheduledWorksFinishedOrCurrent = isScheduledWorkInProgress
      ? // if there is a scheduled work currently in progress, then that one is the most recent
        scheduledWorksCurrent
      : // get the finished SWs and sort them from most recent to oldest
        filteredScheduledWorks.filter((sw) => sw.status === 'finished');

    // if we have at least one finished or in-progress SW
    if (
      scheduledWorksFinishedOrCurrent &&
      scheduledWorksFinishedOrCurrent.length > 0
    ) {
      // make mostRecentScheduledWork be the most recent finished or in-progress SW
      [this.mostRecentScheduledWork] = scheduledWorksFinishedOrCurrent;

      // set future, pending, and rejected quoted services
      // Transform these services so they don't have upsell status anymore
      this.quotedServices.future = this.transformQuotedServices(
        this.mostRecentScheduledWork.services.filter(
          (s) => s.upsell_status === 'future',
        ),
      );
      if (isScheduledWorkInProgress) {
        this.quotedServices.pending = this.transformQuotedServices(
          this.mostRecentScheduledWork.services.filter(
            (s) => s.upsell_status === 'pending',
          ),
        );
      } else {
        this.quotedServices.rejected = this.transformQuotedServices(
          this.mostRecentScheduledWork.services.filter(
            (s) => s.upsell_status === 'rejected',
          ),
        );
      }
    }

    this.quotedServicesUserWants = this.transformQuotedServices(
      cart.filter((s) => s.copy_from_service_id),
    );

    if (
      this.mostRecentScheduledWork &&
      this.mostRecentScheduledWork.scheduled_work_id
    ) {
      this.quotedServicesUserWants = this.quotedServicesUserWants.filter(
        (s) =>
          s.scheduled_work_id !==
          this.mostRecentScheduledWork.scheduled_work_id,
      );
    }

    // if the user has selected at least one service they want to schedule
    if (
      this.quotedServicesUserWants &&
      this.quotedServicesUserWants.length > 0
    ) {
      const associatedSWs = scheduledWorks.filter(
        (sw) => sw.id === this.quotedServicesUserWants[0].scheduled_work_id,
      );
      if (associatedSWs && associatedSWs.length > 0) {
        // determine the SW associated with the services the user wants to schedule
        [this.quotedServicesUserWantsScheduledWork] = associatedSWs;
      }
    }

    // If the scheduled work that the user wants to add services from is the same as our most recent scheduled work,
    // then just ignore this.quotedServicesUserWants because we already accounted for them in this.mostRecentScheduledWork
    if (
      this.quotedServicesUserWantsScheduledWork &&
      this.mostRecentScheduledWork &&
      this.quotedServicesUserWantsScheduledWork.id ===
        this.mostRecentScheduledWork.id
    ) {
      this.quotedServicesUserWants = null;
    }
  };

  getMechanicalScheduledWorks = () => {
    const { scheduledWorks, vehicle } = this.props;
    return scheduledWorks
      .filter((sw) => {
        if (vehicle && sw.vehicle_id) {
          return (
            this.scheduledWorkIncludesMechanicalService(sw) &&
            sw.vehicle_id === vehicle.id
          );
        }
        return this.scheduledWorkIncludesMechanicalService(sw);
      })
      .sort((a, b) => new Moment(b.date) - new Moment(a.date));
  };

  // return whether a given SW has a completed health check but is not yet complete (a.k.a. it's currently in progress)
  scheduledWorkIsCurrent = (sw) => {
    return (
      sw.hc_is_complete &&
      sw.status === 'keys_available' &&
      new Moment(sw.date).isSame(new Date(), 'day')
    );
  };

  scheduledWorkIncludesMechanicalService = (sw) => {
    return sw.services.some(
      (s) => s.schedule_types && s.schedule_types.includes('mechanical'),
    );
  };

  serviceIsInProgress = (service) => {
    return service.upsell_status === 'pending';
  };

  navigateToApproveServicesPage = (scheduledWorkId) => {
    const { account } = this.props;
    browserHistory.push(
      `/approve-services/${account.customer_id}/${scheduledWorkId}`,
    );
  };

  setRecallDisplayName = (recallList) => {
    return `Recall: ${recallList.join(', ')}`;
  };

  // Method for adding and removing services as the customer selects them
  // from the menu of options
  selectService = (obj, isQuantityChange = false, quantity = 1) => {
    // if this is a pending service waiting for approval/rejection from the user
    if (this.serviceIsInProgress(obj) && this.mostRecentScheduledWork) {
      this.navigateToApproveServicesPage(this.mostRecentScheduledWork.id);
      return;
    }
    const serviceOfferingId = obj.service_offering_id;
    const { dispatch, recallList } = this.props;
    const { services } = this.state;

    // Create object from services, to act as a HashMap
    const serviceMap = {};
    services.forEach((s) => {
      if (s.service_offering_id) {
        serviceMap[s.service_offering_id] = s;
      }
    });

    // Toggle service on or off
    if (!isQuantityChange && serviceOfferingId in serviceMap) {
      delete serviceMap[serviceOfferingId];
    } else {
      serviceMap[serviceOfferingId] = { ...obj, quantity };
    }

    // If toggling "on":
    if (serviceOfferingId in serviceMap) {
      // Remove all incompatible_service_offering_ids
      const serviceOfferingIds = obj.incompatible_service_offering_ids || [];
      serviceOfferingIds.forEach((id) => {
        delete serviceMap[id];
      });
    }

    // Always select "default" services
    this.defaultServices.forEach((s) => {
      serviceMap[s.service_offering_id] = s;
    });

    // Set the display name to the recall details if there is a recall and it is selected
    if (
      recallList.length > 0 &&
      serviceOfferingId in serviceMap &&
      obj.short_name.toLowerCase().includes('recall')
    ) {
      serviceMap[
        obj.service_offering_id
      ].display_name = this.setRecallDisplayName(recallList);
    }

    const to = Object.values(serviceMap);

    // If we're entering a state with no basic services at all, we need to abort
    // we don't want them to be able to trick us into allowing only an add-on.
    const hasBasicService = to.some(
      (s) =>
        ['package', 'standard'].includes(s.display_category) &&
        to.filter((x) => !x.included_with_every_mechanical_service).length,
    );

    const hasRecallInCart =
      to.filter((service) => service.short_name.includes('Recall')).length > 0;

    const cartTotal = to
      .map((s) => parseFloat(s.total_cost_dollars) * (s.quantity || 1))
      .reduce((sum, x) => sum + x, 0);
    const minimumAmountReached =
      hasRecallInCart || cartTotal >= this.minimumAmount;

    this.setState({ services: to, hasBasicService, minimumAmountReached });

    dispatch({ type: 'SET_CART', cart: to });
  };

  saveMy = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_CART', cart: [] });

    this.handleContinue();
  };

  handleContinue = () => {
    const { dispatch } = this.props;

    // Clear out any diagnostic data if they went back and
    // rerouted to expert services instead
    dispatch({
      type: 'SET_DIAGNOSTIC',
      diagnostic: {},
    });

    // If the user is logged in, we can skip the "Account Creation" page.
    if (hasAccessToken()) {
      browserHistory.push('/pricing/schedule');
      return;
    }
    browserHistory.push('/pricing/account');
  };

  getPriceHtml = (service) => {
    const price = service.total_cost_dollars;
    const isFree = price === 0;

    const isDefaultService = this.defaultServices.some(
      (s) => s.service_offering_id === service.service_offering_id,
    );

    let priceHtml;
    if (service.original_value_dollars) {
      priceHtml = (
        <div className="price">
          <span className="strike">
            {formatPrice(service.original_value_dollars)}
          </span>{' '}
          <span className="discountedPrice">{formatPrice(price)}</span>
        </div>
      );
    } else if (isDefaultService && isFree) {
      priceHtml = (
        <span className="price">Included Free With Every Service!</span>
      );
    } else if (isFree) {
      priceHtml = <span className="price">Free!</span>;
    } else if (service.variable_pricing) {
      priceHtml = <span className="price">Starting at {formatPrice(price)}</span>;
    } else {
      priceHtml = <span className="price">{formatPrice(price)}</span>;
    }
    return priceHtml;
  };

  expandParentService = (service) => {
    const { parentServicesToExpand } = this.state;
    // If the service was already expanded, remove it so it collapses
    if (parentServicesToExpand.includes(service.service_offering_id)) {
      const index = parentServicesToExpand.indexOf(service.service_offering_id);
      this.setState({
        parentServicesToExpand: update(parentServicesToExpand, {
          $splice: [[index, 1]],
        }),
      });
    } else {
      this.setState({
        parentServicesToExpand: [
          ...parentServicesToExpand,
          service.service_offering_id,
        ],
      });
    }
  };

  handleDiagnosticSelect() {
    const { dispatch } = this.props;
    dispatch({
      type: 'SET_DIAGNOSTIC',
      diagnostic: { selected: true },
    });
    dispatch({ type: 'SET_CART', cart: [] });
    browserHistory.push('/pricing/diagnostic');
  }

  calculateVehicleOilCost(service) {
    const { vehicle } = this.props;
    if (vehicle && vehicle.oil_capacity) {
      const oilCapacity = Math.ceil(parseFloat(vehicle.oil_capacity));

      const BASE_QUARTS = 5;

      const extraQuarts = Math.max(0, oilCapacity - BASE_QUARTS || 0);
      const extraOilCostDollars =
        (service.extra_oil_cost_per_qt_dollars || 0) * extraQuarts;

      return extraOilCostDollars;
    }
    return 0.0;
  }


  renderServiceNameColumn = (service, inlineServiceImage, recallList) => {
    return (
      <>
        {/*<Row
          className={
            inlineServiceImage ? 'marginBottom0 alignedCenter' : 'marginBottom0'
          }
        >*/}
        <div className="service-img">
            {inlineServiceImage && (
              <img
                className="inline-service-image"
                alt=""
                style={{
                  verticalAlign: 'middle',
                }}
                src={inlineServiceImage}
              />
            )}
            { !inlineServiceImage && (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_400_1428)">
                <path d="M7.01967 14.8163C4.98193 16.7755 3.0574 18.6122 2.26495 19.3469C1.92533 19.5918 1.69891 19.9591 1.4725 20.4489C1.13288 21.1836 1.01967 21.7959 1.4725 22.4081C2.26495 23.6326 3.73665 23.6326 4.64231 22.653L9.62344 17.5102" stroke="#181818" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15.2837 11.3877C16.7554 9.67342 17.8875 8.44893 18.1139 8.20403C18.1139 8.20403 18.1139 8.20403 18.2271 8.20403C18.7931 8.20403 24.7931 8.57138 23.2082 3.18362C23.2082 3.06117 23.095 3.06117 23.095 3.18362L22.9818 3.55097C22.4158 4.65301 21.6233 5.3877 20.4912 5.75505C20.378 5.75505 20.2648 5.75505 20.1516 5.75505C19.9252 5.75505 19.6988 5.6326 19.4724 5.51015L19.2459 5.26526C18.6799 4.77546 18.5667 3.91832 18.9063 3.18362C19.0195 2.44893 19.5856 1.71423 20.2648 1.22444L20.8309 0.857092V0.734643C15.2837 -0.489846 16.1893 5.51015 16.1893 5.51015L13.0195 9.30607" stroke="#181818" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M20.9434 20.9388L18.3397 23.3878L9.05664 13.7143L11.8868 10.7755L20.9434 20.9388Z" stroke="#181818" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.96263 12.8571L2.60414 5.14284L1.47207 4.89794L0.566406 2.93876L2.0381 1.34692L3.73622 2.32652L4.07584 3.91835L11.0947 11.5102L9.96263 12.8571Z" stroke="#181818" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.1133 14.0817L16.868 19.347" stroke="#181818" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_400_1428">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            )}
          </div>
          <div className="service-content">
            <div className="d-flex">
              <h3 className="label">
                {service.short_name.toLowerCase().includes('recall')
                  ? this.setRecallDisplayName(recallList)
                  : service.long_name}
                <sup>{service.variable_pricing && '*'}</sup>
              </h3>
            </div>
          </div>
        {/*</Row>*/}
      </>
    );
  };

  renderExpandIcon = (service) => {
    const { parentServicesToExpand } = this.state;
    return (
      <Icon className="check-icon" small>
        {parentServicesToExpand.includes(service.service_offering_id)
          ? 'keyboard_arrow_down'
          : 'keyboard_arrow_right'}
      </Icon>
    );
  };

  renderQuantityColumn = (serviceObj) => {
    const { cart } = this.props;
    // Allow customers to select up to 12 of the same service
    const maxQuantity = 6;
    const quantityDropdownList = Array.from(
      { length: maxQuantity },
      (_, i) => i + 1,
    );
    const selectedService = cart?.find(
      (s) => s.service_offering_id === serviceObj.service_offering_id,
    );
    
    return (
      <span className="quantity-input-wrapper">
        <Select
          className="quantity-input"
          name="serviceQuantity"
          value={selectedService?.quantity || 1}
          // Quantity cannot be changed on automatically added services
          disabled={serviceObj.included_with_every_mechanical_service}
          onChange={(e) => {
            this.selectService(serviceObj, true, Number(e.currentTarget.value));
          }}
        >
          {quantityDropdownList.map((quantity) => (
            <option value={`${quantity}`}>{quantity}</option>
          ))}
        </Select>
        {/*<button className="minus-btn button" onClick={this.handleDecrement}>-</button>
        <input 
          name="serviceQuantity"
          type="number" 
          value={selectedService?.quantity || this.state.quantity} 
          onChange={this.handleChange} 
          min="1" 
          max={maxQuantity}
        />
        <button className="plus-btn button" onClick={this.handleIncrement}>+</button>*/}
      </span>
    );
  };

  renderDetailsColumn = (serviceCopy, hasModal) => {
    const { recallList, z3pConfiguration } = this.props;
    const { is_performing_recalls } = z3pConfiguration;
    if (this.serviceIsInProgress(serviceCopy)) {
      return (
        <button
          type="button"
          className="button-to-link more-info"
          onClick={() => {
            if (this.mostRecentScheduledWork)
              this.navigateToApproveServicesPage(
                this.mostRecentScheduledWork.id,
              );
          }}
        >
          View
        </button>
      );
    }

    if (hasModal) {
      const shouldDisplayRecall =
        is_performing_recalls &&
        serviceCopy.short_name.toLowerCase().includes('recall');
      return (
        <Modal
          header={serviceCopy.long_name}
          fixedFooter
          trigger={<span className="more-info">Details</span>}
        >
          {serviceCopy.descriptions.map((description) => (
            <div key={description.id}>
              <h5 className="modal-header">{description.subheader}</h5>
              <p className="neue">{description.text}</p>
            </div>
          ))}
          {!!shouldDisplayRecall && (
            <div>
              <h5 className="modal-header">Recall Details</h5>
              <p className="neue">{recallList.join('\n')}</p>
            </div>
          )}
        </Modal>
      );
    }

    return null;
  };

  getIsSelected = (service) => {
    const { services } = this.state;
    return getServiceById(services, service.service_offering_id);
  };

  getClasses = (service) => {
    let classes = 'service valign-wrapper';
    if (this.getIsSelected(service)) {
      classes += ' active';
    }

    if (service.display_emphasize) {
      classes += ' emphasize-service';
    }
    return classes;
  };

  renderChildService = (childService, index) => {
    const { recallList, z3pConfiguration } = this.props;
    const {
      is_showing_quantity_in_booking: isShowingQuantityInBooking,
    } = z3pConfiguration;

    // Note:
    // The columns in this Row are allowed to exceed 12 without wrapping.
    // This is because we use valign-wrapper on this Row.
    return (
      <Row key={index} className={this.getClasses(childService)}>
        <Col
          onClick={() => this.selectService(childService)}
          s={2}
          l={1}
          offset="l1"
        >
          {this.serviceIsInProgress(childService) ? (
            <Icon className="check-icon" small>
              notifications_active
            </Icon>
          ) : (
            <CheckboxIcon isSelected={this.getIsSelected(childService)} />
          )}
        </Col>

        {this.renderServiceNameColumn(
          childService,
          childService.inline_image_url &&
            `${childService.inline_image_url}?fit=max&h=35&auto=format,compress`,
          recallList,
        )}

        <Col
          onClick={() => this.selectService(childService)}
          s={3}
          l={3}
          className="right-align"
        >
          {this.getPriceHtml(childService)}
        </Col>

        {isShowingQuantityInBooking && (
          <Col s={3} l={2} className="right-align" style={{ maxWidth: '90px' }}>
            {this.getIsSelected(childService) &&
              !childService.included_with_every_mechanical_service &&
              this.renderQuantityColumn(childService)}
          </Col>
        )}

        <Col s={2} l={1} className="right-align">
          {this.renderDetailsColumn(
            childService,
            childService.descriptions && childService.descriptions.length > 0,
          )}
        </Col>
      </Row>
    );
  };

  renderQuotedServicesUserWantsHeader = () => {
    const { vehicle } = this.props;
    if (
      vehicle &&
      this.quotedServicesUserWantsScheduledWork &&
      this.quotedServicesUserWantsScheduledWork.vehicle_id === vehicle.id
    ) {
      return (
        <td className="h">
          Recommended Services from{' '}
          {Moment(this.quotedServicesUserWantsScheduledWork.date).format(
            'MMM DD, YYYY',
          )}
        </td>
      );
    }
    return <td className="h">Services Previously Added to Cart</td>;
  };

  render() {
    const {
      vehicle,
      account,
      priceList,
      recallList,
      z3pConfiguration,
      cart,
    } = this.props;
    const {
      openRecallModal,
      minimumAmountReached,
      hasBasicService,
      parentServicesToExpand,
      services,
    } = this.state;
    const { oilSpecifications = [] } = vehicle || {};
    const viscosities = new Set(
      oilSpecifications.map((spec) => spec.viscosity),
    );
    const {
      is_performing_recalls: isPerformingRecalls,
      has_diagnostic_service: hasDiagnosticService,
      is_showing_quantity_in_booking: isShowingQuantityInBooking,
    } = z3pConfiguration;

    const cartIDs = new Set(cart.map((s) => s.service_offering_id));
    const servicesIDs = new Set(services.map((s) => s.service_offering_id));
    this.setupPriceList(vehicle, priceList, hasDiagnosticService);

    // If there are different services in our cart, reload data to use cart data
    if (!_.isEqual(cartIDs, servicesIDs)) this.reloadData();

    const serviceToHtml = (service, index) => {
      // Create a copy of the service object, to allow mutation
      const serviceCopy = { ...service };

      // TODO: Go back to using oil_specifications and come up with a true recommended oil.
      // For now we will use oil_viscosities to reduce latency (and avoid joins to oil_specifications).
      if (
        serviceCopy.long_name.includes('Conventional') &&
        viscosities.length === 1 &&
        viscosities[0] === '0W-20'
      ) {
        return null;
      }

      if (serviceCopy.add_oil_cost) {
        // If the service requires price adjustment based on oil capacity,
        // adjust the price as required
        const extraOilCostDollars = this.calculateVehicleOilCost(serviceCopy);
        serviceCopy.total_cost_dollars += extraOilCostDollars;
        serviceCopy.parts_cost_dollars += extraOilCostDollars;
      }

      const priceHtml = this.getPriceHtml(serviceCopy);
      const classes = this.getClasses(serviceCopy);
      const isSelected = this.getIsSelected(serviceCopy);

      const hasModal =
        serviceCopy.descriptions && serviceCopy.descriptions.length > 0;

      const inlineServiceImage =
        serviceCopy.inline_image_url &&
        `${serviceCopy.inline_image_url}?fit=max&h=50&auto=format,compress`;

      // If the service is a parent service, use this return instead
      if (
        !!serviceCopy.child_service_offerings &&
        serviceCopy.child_service_offerings.length > 0
      ) {
        return (
          <Col s={12} m={6} l={4}>
            <div key={index} className={classes}>
              <div className="serviceBox">
                <div className="service_inner">
                  <Col
                    onClick={() => this.expandParentService(serviceCopy)}
                    s={2}
                    l={1}
                  >
                    {this.renderExpandIcon(service)}
                  </Col>

                  <Col s={5} l={8}>
                    <Row
                      className={
                        inlineServiceImage
                          ? 'marginBottom0 alignedCenter'
                          : 'marginBottom0'
                      }
                    >
                      {inlineServiceImage && (
                        <Col s={12} m={6} l={3} xl={2}>
                          <span className="inline-image-container">
                            <img
                              className="inline-service-image"
                              alt=""
                              style={{
                                verticalAlign: 'middle',
                              }}
                              src={inlineServiceImage}
                            />
                          </span>
                        </Col>
                      )}
                      <Col
                        onClick={() => this.expandParentService(serviceCopy)}
                        s={12}
                        m={6}
                        l={9}
                        xl={10}
                      >
                        <span className="label">{serviceCopy.long_name}</span>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    onClick={() => this.expandParentService(serviceCopy)}
                    s={4}
                    l={5}
                    className="right-align"
                  />

                  <Col s={2} l={1} className="right-align">
                    {this.renderDetailsColumn(serviceCopy, hasModal)}
                  </Col>
                </div>
              </div>
            </div>

            {parentServicesToExpand.includes(serviceCopy.service_offering_id) &&
              !!serviceCopy.child_service_offerings &&
              serviceCopy.child_service_offerings.map((childService) =>
                this.renderChildService(childService, index),
              )}
          </Col>
        );
      }

      // Note:
      // The columns in this Row are allowed to exceed 12 without wrapping.
      // This is because we use valign-wrapper on this Row.
      return (
        <Col s={12} m={6} l={6} xl={4} className="mb-20">
          <div key={index} className={classes} onClick={() => this.selectService(serviceCopy)}>
            <div className="serviceBox">
              <div className="service_inner">
                {this.renderServiceNameColumn(
                  serviceCopy,
                  inlineServiceImage,
                  recallList,
                )}
                <div className="price-box">
                  <div  className="service_checkbox">
                    {this.serviceIsInProgress(serviceCopy) ? (
                      <Icon className="check-icon" small>
                        notifications_active
                      </Icon>
                    ) : (
                      <Checkbox
                        id={index}
                        className="filled-in"
                        checked={isSelected ? true : false}
                        onChange={() => this.selectService(serviceCopy)}
                      />
                    )}
                  </div>
                  <h5
                    className="tire-card-price"
                  >
                    {priceHtml}
                  </h5>
                </div>
              </div>
              <div className="service_inner qty">
                {isShowingQuantityInBooking && (
                  <div
                    className="qty_box"
                  >
                    {isSelected &&
                      !serviceCopy.included_with_every_mechanical_service &&
                      this.renderQuantityColumn(serviceCopy)}
                  </div>
               )}
                {/*<div className="price-box">
                 <h5
                    onClick={() => this.selectService(serviceCopy)}
                    className="tire-card-price"
                  >
                    {priceHtml}
                  </h5>
                </div>*/}
                <div className="right-align">
                  {this.renderDetailsColumn(serviceCopy, hasModal)}
                </div>
              </div>
            </div>
          </div>
        </Col>
      );
    };

    // Find services that have child service offerings, meaning they are a parent service
    const parentServices = this.availableServices.filter(
      (service) =>
        !!service.child_service_offerings &&
        service.child_service_offerings.length > 0,
    );
    parentServices.forEach((parent) => {
      parent.child_service_offerings = this.availableServices
        .filter(
          (service) =>
            service.parent_service_offering &&
            service.parent_service_offering === parent.service_offering_id,
        )
        .sort((a, b) => a.display_order - b.display_order);
    });

    const recallService = getServiceByName(priceList, 'Recall');
    let recallHtml = [];
    if (isPerformingRecalls && recallList.length > 0 && recallService) {
      recallHtml = [serviceToHtml(recallService, 0)];
    }

    const getStandardAndPackageHtml = (category) => {
      return _.values(this.availableServices)
        .filter(
          (service) =>
            service.display_category === category &&
            !service.parent_service_offering,
        )
        .sort((a, b) => a.display_order - b.display_order)
        .map(serviceToHtml)
        .filter((service) => !!service);
    };

    const addOnHtml = _.values(this.availableServices)
      .filter(
        (service) =>
          service.display_category === 'addon' &&
          !service.parent_service_offering,
      )
      .sort((a, b) => a.display_order - b.display_order)
      .map(serviceToHtml);

    const quotedServicesUserWantsHtml = _.values(
      this.quotedServicesUserWants,
    ).map(serviceToHtml);
    const quotedServicesHtml = {
      future: _.values(this.quotedServices.future).map(serviceToHtml),
      pending: _.values(this.quotedServices.pending).map(serviceToHtml),
      rejected: _.values(this.quotedServices.rejected).map(serviceToHtml),
    };

    // Set alert message to be displayed under "continue" button
    let alertText = '';
    if (!hasBasicService) {
      alertText = 'You must select at least one Package or Standard Service';
    } else if (!minimumAmountReached) {
      alertText = `The minimum order amount is $${this.minimumAmount}`;
    }

    const enableContinue =
      isFromQuote(cart) || (hasBasicService && minimumAmountReached);

    // If package/standard service not chosen or minimum not reached, disable Continue
    const continueDiv = (
      <div>
        <span
          style={{ marginTop: "40px", marginBottom: 10 }}
          disabled={!enableContinue}
          onClick={() => this.handleContinue()}
          className="btn btn-large header"
          role="button"
          tabIndex="-1"
          onKeyPress={this.handleKeyPress}
        >
          Continue
         {/* <i className="material-icons right">play_arrow</i>*/}
        </span>

        {!enableContinue && <div className="neue error">{alertText}</div>}

        {/* If not logged in, show "Not Ready?" */}
        {!account.customer_id && (
          <p className="neue service_text">
            Not Ready?&nbsp;
            <span
              onClick={() => this.saveMy()}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              role="button"
              tabIndex="-1"
              onKeyPress={this.handleKeyPress}
            >
              Save my info and create my account
            </span>
          </p>
        )}
      </div>
    );

    let recallString = '';
    if (isPerformingRecalls && recallList) {
      recallString = recallList.join('\n');
    }
    const cartTotalDollars = cart
      .map((s) => parseFloat(s.total_cost_dollars) * (s.quantity || 1))
      .reduce((sum, x) => sum + x, 0);
    return (
      <Pricing
        currentStep="Services"
        h1="Select Your Services"
        showFindOilLink
        showContinue
        enableContinue={enableContinue}
        alertText={alertText}
        onContinue={this.handleContinue}
      >
        <Modal
          header="Recall Notice"
          className="recall-modal"
          fixedFooter
          open={openRecallModal}
        >
          <div>
            <p className="neue recall-detail">{recallString}</p>
            <h5 className="modal-header">Your vehicle has an active recall</h5>
            <p className="neue">
              We looked up your vehicle and found that there is an active
              recall. We added a recall service to your cart, free of charge.
            </p>
            <p className="neue">
              If you have already taken care of this recall, feel free to
              uncheck this service.
            </p>
          </div>
        </Modal>
        <div className="dots-container neue">
          {this.quotedServices.pending &&
            this.quotedServices.pending.length > 0 && (
              <table className="hack mb-20">
                <tbody>
                  <tr>
                    <td className="h">
                      Awaiting Your Approval: Recommended Services from{' '}
                      {Moment(this.mostRecentScheduledWork.date).format(
                        'MMM DD, YYYY',
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          {isPerformingRecalls && !!recallHtml.length && (
            <span>
              <table className="hack mb-20">
                <tbody>
                  <tr>
                    <td className="h">Your vehicle has an active recall</td>
                  </tr>
                </tbody>
              </table>
              {recallHtml}
            </span>
          )}
          {quotedServicesHtml.pending}
          {this.quotedServices.future && this.quotedServices.future.length > 0 && (
            <table className="hack mb-20">
              <tbody>
                <tr>
                  <td className="h">
                    Recommended Services from{' '}
                    {Moment(this.mostRecentScheduledWork.date).format(
                      'MMM DD, YYYY',
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {quotedServicesHtml.future}
          {quotedServicesHtml.rejected}
          {this.quotedServicesUserWants &&
            this.quotedServicesUserWants.length > 0 && (
              <table className="hack mb-20">
                <tbody>
                  <tr>{this.renderQuotedServicesUserWantsHeader()}</tr>
                </tbody>
              </table>
            )}
          {quotedServicesUserWantsHtml}
          {!!getStandardAndPackageHtml('package').length && (
            <span>
              <table className="hack mb-20">
                <tbody>
                  <tr>
                    <td className="h">Service Packages</td>
                  </tr>
                </tbody>
              </table>
              <Row>
                {getStandardAndPackageHtml('package')}
              </Row>
            </span>
          )}
          <table className="hack mb-20">
            <tbody>
              <tr>
                <td className="h"><h5>Standard Services</h5></td>
              </tr>
            </tbody>
          </table>
          <Row>
            {getStandardAndPackageHtml('standard')}
          </Row>
          {!!addOnHtml.length && (
            <table className="hack mb-20">
              <tbody>
                <tr>
                  <td className="h"><h5>Add-on Services</h5></td>
                </tr>
              </tbody>
            </table>
          )}
          <Row>
            {addOnHtml}
          </Row>
          <Row className="tire-total">
            <Col className="services-content">
                <ul>
                  <li className="total">
                      <span className="neue">
                        Total before tax:
                      </span>
                      <span className="header">{formatPrice(cartTotalDollars)}</span>
                  </li>
                </ul>
            </Col>
          </Row>
        </div>
        <Row>
          <Col s={12} m={12}>
            <p className="service_text">
              * Pricing varies. Your final price may be higher. We will contact
              you before beginning work to authorize any additional charges.
            </p>
            <span className="service_text">Need something but don&apos;t see it on the list?</span>
            <br />
            {!isNonAutomobileType(vehicle?.vehicleType) &&
            hasDiagnosticService ? (
              <Button onClick={this.handleDiagnosticSelect}>
                Book a Diagnostic Service
              </Button>
            ) : (
              <span className="service_text">
                Email us at {getEmailSupportLink()} or call{' '}
                {getPhoneSupportLink()}
              </span>
            )}
          </Col>
          </Row>
          
          {continueDiv}

      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    vehicle: state.ui.pricing,
    cart: state.ui.cart,
    account: state.ui.account,
    customerLocation: state.ui.customerLocation,
    referrer: state.ui.referrer,
    priceList: state.ui.priceList,
    scheduledWorks: state.ui.scheduledWorks,
    recallList: state.ui.recallList,
    homeAddress: state.ui.homeAddress,
    autoSelectServiceOfferingIds: state.ui.autoSelectServiceOfferingIds,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Services);

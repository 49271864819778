/* globals */
import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Card, Col } from 'react-materialize';
import _ from 'lodash';
import Pricing from './components/Pricing';
import { apiGet, hasAccessToken } from '../brainApi';
import {
  alphabeticalObjectSort,
  getURLQuery,
  isFromQuote,
  setPageTitle,
  setUserWorkplaceAndRedirect,
  shouldAutoSelectLocation,
} from '../helper';
import AddressCard from './components/AddressCard';
import { setUserWorkplace } from '../placeHelper';
import {
  loadClientLocationDetailsAndPriceList,
  loadClientLocations,
} from '../brain2';
import { isNonAutomobileType } from '../vehicleHelper';

const Place = (props) => {
  setPageTitle('Select Place');
  const {
    dispatch,
    customerLocation,
    clientLocations,
    homeAddress,
    vehicle,
    pricing,
    z3pConfiguration,
    cart,
  } = props;
  const {
    skip_simple_services_to,
    service_locations: serviceLocations,
    is_skipping_vehicle,
  } = z3pConfiguration;

  const [addresses, setAddresses] = useState([]);
  const [customerHasClientLocation, setCustomerHasClientLocation] = useState(
    false,
  );
  const [isAddingNewClientLocation, setIsAddingNewClientLocation] = useState(
    false,
  );

  const hasWorkplaceService = serviceLocations.includes('workplace');
  const hasShopService = serviceLocations.includes('shop');
  const hasHomeService = serviceLocations.includes('home');

  useEffect(() => {
    dispatch({
      type: 'SET_CHANGE_BOOKING',
      changeBooking: false,
    });

    dispatch({ type: 'SET_COMPATIBLE_DATES', compatibleDates: [] });
    dispatch({ type: 'SET_SCHEDULE', schedule: {} });

    loadClientLocationDetailsAndPriceList(
      dispatch,
      customerLocation?.clientLocationId,
      vehicle?.vehicle_id,
    );
    const clientLocationExists =
      customerLocation && !!customerLocation.clientLocationId;
    // if customer is not logged in
    if (!userIsLoggedIn()) {
      const hasHomeAddress = homeAddress && !_.isEmpty(homeAddress.address);
      // if user is signing up and has workplace XOR an address set, move forward to services
      if (clientLocationExists ? !hasHomeAddress : hasHomeAddress)
        handleContinue();
      else if (!clientLocationExists && !hasHomeAddress) {
        browserHistory.push('/pricing/begin');
        return;
      }
    }

    // If the only service location is workplace or shop and there is only 1 client location or a location has already been selected, skip /place
    if (shouldAutoSelectLocation(serviceLocations, clientLocations)) {
      const clId = clientLocationExists
        ? customerLocation.clientLocationId
        : clientLocations[0].client_location_id;
      setUserWorkplaceAndRedirect(
        clId,
        dispatch,
        skip_simple_services_to,
        is_skipping_vehicle,
        pricing,
      );
    } else if (
      serviceLocations.length === 1 &&
      !serviceLocations.includes('home') &&
      clientLocations.length > 1
    ) {
      // If the Pro only offers shop/workplace services and there are multiple locations, open the location selection form
      setIsAddingNewClientLocation(true);
    }

    // At this point, either user is logged in or user has both a workplace and a home address
    setCustomerHasClientLocation(clientLocationExists);
    fetchUserAddresses();
  }, []);

  useEffect(() => {
    loadClientLocations(dispatch);
  }, []);

  const openClientLocationForm = () => {
    setIsAddingNewClientLocation(true);
    if (clientLocations.length === 1) {
      setIsAddingNewClientLocation(false);
      const { client_location_id: clId } = clientLocations[0];
      setWorkplaceAsync(clId).then(() => {
        onSelectWorkplace();
      });
    }
  };

  const userIsLoggedIn = () => {
    return hasAccessToken();
  };

  const fetchUserAddresses = () => {
    if (!userIsLoggedIn()) {
      const addresses =
        homeAddress && homeAddress.address ? [homeAddress.address] : [];
      setAddresses(addresses);
    } else {
      apiGet('/customer/address')
        .then((res) => res.json())
        .then((json) => {
          const { addresses } = json;
          const activeAddresses = addresses.filter((a) => !a.is_deleted);
          alphabeticalObjectSort(activeAddresses, 'display_name');
          setAddresses(activeAddresses);
        });
    }
  };

  const addNewAddress = () => {
    const redirect = encodeURIComponent('/pricing/place');
    return browserHistory.push(`/add-home-address?redirect=${redirect}`);
  };

  const setWorkplaceAsync = (clientLocationId) => {
    const vehicle_id = vehicle?.vehicle_id;
    return setUserWorkplace(dispatch, clientLocationId, vehicle_id, () => {
      setCustomerHasClientLocation(true);
      setIsAddingNewClientLocation(false);
    });
  };

  const onSelectPlace = ({ isWork = false, address = {} }) => {
    if (isWork) {
      dispatch({
        type: 'SET_HOME_ADDRESS',
        addressIDSelected: null,
        addressNameSelected: null,
        address,
      });
      handleContinue();
    } else {
      dispatch({
        type: 'SET_HOME_ADDRESS',
        addressIDSelected: address && address.customer_address_id,
        addressNameSelected:
          address && (address.display_name || address.address_line_1),
        address,
      });
      dispatch({
        type: 'SET_KEYOSK',
        keyosk: null,
      });
      dispatch({
        type: 'SET_PARKING',
        parking: null,
      });
      handleContinue();
    }
  };

  const onSelectWorkplace = () => {
    onSelectPlace({
      isWork: true,
      address: homeAddress && homeAddress.address,
    });
  };

  const handleContinue = () => {
    const query = getURLQuery();
    const redirect = query.redirect && decodeURIComponent(query.redirect);
    const skipToNonAutomobileServices = isNonAutomobileType(
      pricing?.vehicleType,
    );
    if (redirect) {
      browserHistory.push(redirect);
    } else if (isFromQuote(cart)) {
      browserHistory.push(
        `/pricing/${userIsLoggedIn() ? 'schedule' : 'begin'}`,
      );
    } else if (skipToNonAutomobileServices || is_skipping_vehicle) {
      browserHistory.push(`/pricing/services`);
    } else if (
      skip_simple_services_to &&
      ['services', 'detailing', 'tires', 'diagnostic'].includes(
        skip_simple_services_to,
      )
    ) {
      browserHistory.push(`/pricing/${skip_simple_services_to}`);
    } else {
      browserHistory.push('/pricing/simple');
    }
  };

  let addressCards = [];
  if (hasHomeService && addresses && !!addresses.find((a) => !_.isEmpty(a))) {
    addressCards = addresses.sort((addr) => addr.is_primary ? -1 : 1).map(
      (address) =>
        !address.is_deleted && (
          <AddressCard
            id={address.customer_address_id}
            selectable
            address={address}
            onSelect={() => {
              onSelectPlace({ address });
            }}
          />
        ),
    );
  }
  if (hasWorkplaceService || hasShopService) {
    if (
      customerHasClientLocation ||
      (isAddingNewClientLocation && clientLocations.length > 1)
    ) {
      addressCards.unshift(
        <AddressCard
          id={customerLocation.location}
          editable={isAddingNewClientLocation}
          selectable={!isAddingNewClientLocation}
          workplace={customerLocation.location}
          isNewWorkplace={isAddingNewClientLocation}
          setWorkplaceAsync={setWorkplaceAsync}
          onCancel={() => setIsAddingNewClientLocation(false)}
          onSelect={() => {
            onSelectWorkplace();
          }}
          hasShopService={hasShopService}
        />,
      );
    } else {
      addressCards.unshift(
        <Col l={3} m={6} s={12}>
          <Card
            className="swCard newVehicleCard waves-effect waves-light"
            align="center"
            title={hasShopService ? 'Select Your Shop' : 'Add Your Workplace'}
            onClick={openClientLocationForm}
          />
        </Col>,
      );
    }
  }

  return (
    <Pricing
      currentStep="Location"
      h1="Schedule Your Service"
      h2="Where do you want service?"
    >
    <div className="row place_box">
      {addressCards}
    </div>
      {!hasShopService && hasHomeService && userIsLoggedIn() && (
        <div className="row">
          <Col l={3} m={6} s={12}>
            <Card
              className="swCard newVehicleCard waves-effect waves-light btn-large btn"
              align="center"
              title="Add a Home Address"
              onClick={() => addNewAddress()}
            />
          </Col>
        </div>
      )}
    </Pricing>
  );
};

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
    vehicles: state.ui.vehicles,
    vehicle: state.ui.pricing,
    cart: state.ui.cart,
    customerLocation: state.ui.customerLocation,
    clientLocations: state.ui.clientLocations,
    homeAddress: state.ui.homeAddress,
    z3pConfiguration: state.ui.z3pConfiguration,
    availableScheduleTypes: state.ui.availableScheduleTypes,
  };
}

export default connect(mapStateToProps, null)(Place);

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div id="privacy" className="privacy-policy">
      <h4 className="privacy-header">Zippity Privacy Policy</h4>
      <span variant="body1" className="privacy-paragraph">
        Last Updated: June 1, 2021
      </span>
      <h5 className="privacy-paragraph">1. Introduction</h5>
      <p className="privacy-paragraph">
        This Privacy Policy describes the practices of Zippity, Inc., a Delaware
        corporation (“Zippity,” “we”, “us”, “our”) with respect to our
        collection, use and disclosure of personal information we collect from
        and about users (“you”, “your” or “users”) of our website
        zippitycars.com (the “Zippity Site”) or of third-party websites that are
        authorized by us to use our cloud-based customer onsite experience
        platform (the “Zippity Platform”).
      </p>
      <p className="privacy-paragraph">
        <span>Partner Sites</span>
      </p>
      <p className="privacy-paragraph">
        A number of Zippity’s enterprise customers are authorized by Zippity to
        use the Zippity Platform via their websites (each a “Partner Site”) to
        deliver enhanced features and functionalities to their end users. To the
        extent the Zippity Platform is embedded in such Partner Site such that
        it enables Zippity to collect personal information via the Partner Site,
        such collection by Zippity is subject to this Privacy Policy. Any other
        collection of personal information via the Partner Site, including by
        its third-party operator, is outside the scope of this Privacy Policy.
        Except for its own collection via the Zippity Platform described in this
        paragraph, Zippity is not responsible for the privacy practices or the
        content of the Partner Sites or any other websites to which the Zippity
        Site links or which link to the Zippity Site or link to or embed the
        Zippity Platform. We encourage you to read the posted privacy policies
        of all relevant third parties whenever interacting with any website not
        operated by Zippity.
      </p>
      <p className="privacy-paragraph">
        <span>Additional Definitions</span>
      </p>
      <p className="privacy-paragraph">
        As used in this Privacy Policy: “Buyers” are persons purchasing Services
        via the Zippity Site or Zippity Platform; “Professionals” are persons
        providing such Services; and “users” or “Users” are Buyers,
        Professionals and other users of the Zippity Site or Zippity Platform.
      </p>
      <p className="privacy-paragraph">
        <span>Effectiveness of this Privacy Policy; Revisions</span>
      </p>
      <p className="privacy-paragraph">
        By accessing or using the Zippity Site or Zippity Platform (including
        via a Partner Site), or by registering for or receiving our car
        maintenance services (“Services”) available via the Zippity Site, you
        acknowledge that you have read and understand the terms of this Privacy
        Policy, which is hereby incorporated by reference within our applicable
        terms and conditions. Zippity may revise this Privacy Policy from time
        to time. Any such revisions will be reflected by the last updated date
        above and will take effect as of that date. Please revisit this page to
        stay aware of any changes.
      </p>
      <h5 className="privacy-paragraph">
        2. Collection and Use of Personal Information
      </h5>
      <p className="privacy-paragraph">
        We collect information about you, including information that directly or
        indirectly identifies you, through your use of the Zippity Site or
        Zippity Platform:
      </p>
      <ul>
        <li>
          When you (or other users) provide the information within the Zippity
          Site or Zippity Platform, through filling out forms or otherwise
          submitting, posting or uploading personal information, or by
          corresponding with us regarding the Zippity Site, Zippity Platform or
          any related matter;
          <p className="privacy-paragraph" />
          <ul>
            <li>
              When we receive the information from third parties (such as the
              enterprise customers who operate Partner Sites) or from publicly
              available data sources (such as automotive directories); and
              <ul>
                <li>
                  Using automated tracking technologies, such as cookies and web
                  server logs, to collect information about your computer or
                  mobile device and internet connection (including your IP
                  address, operating system and browser type and version) and
                  details regarding your use of the Zippity Site and Zippity
                  Platform.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p className="privacy-paragraph">
        We use such personal information to: provide you with information,
        products or services that you request or that we are otherwise
        contractually obligated to provide; assist you in obtaining or providing
        services via the Zippity Site or Zippity Platform; provide you with
        support and training; complete/fulfill transactions (including sending
        invoices and collecting payments) and contests; improve our products,
        services, and operations; personalize and continually improve user
        experience; market our products and services; perform statistical,
        demographic and marketing analyses of users; and facilitate our other
        legitimate business purposes, including managing our security
        obligations, protecting the safety and well-being of Zippity, our users
        and others, and complying with applicable laws and regulations.
      </p>
      <p className="privacy-paragraph">
        Without limiting the above, we have provided additional detail below
        regarding certain categories of information that we collect:
      </p>
      <p className="privacy-paragraph">
        <span>Registration Information</span>
      </p>
      <p className="privacy-paragraph">
        If you register an account that allows you to buy or sell services via
        the Zippity Site or Zippity Platform, we collect registration
        information that may include first name, last name, password, street
        address, city, state, zip/postal code, country, phone number, and email
        address. Professionals may also set up their Zippity profile to describe
        their experience, rates and qualifications, which in turn will help
        potential Buyers decide whether/how to make use of your services. Some
        registration information will be mandatory and some registration
        information is optional.
      </p>
      <p className="privacy-paragraph">
        <span>Payment Information</span>
      </p>
      <p className="privacy-paragraph">
        Zippity offers a payment system that allows Buyers and Professionals to
        make credit card payments via the Zippity Site and Zippity Platform (the
        “Payment System”). If you make use of the Payment System, you will be
        required to enter your credit card number and/or other financial
        institution information. That information will be collected directly by
        a third-party payment processor contracted by Zippity, subject to the
        payment processor’s privacy policy. Through a process designed by the
        payment processor to facilitate secure treatment of payment information,
        Zippity then receives information relating to the transaction, which
        Zippity considers personal information subject to this Privacy Policy.
      </p>
      <p className="privacy-paragraph">
        <span>Buyer-Professional Order Information and Call and SMS Data</span>
      </p>
      <p className="privacy-paragraph">
        Among other features and functionalities, the Zippity Site and Zippity
        Platform facilitate the placement and acceptance of work orders by and
        between Buyers and Professionals, including by facilitating
        communications between Buyers and Professionals. Although these work
        orders and communications are controlled by individual users, not
        Zippity, Zippity may, in connection with facilitating such work orders
        and communications, collect related data, including information
        contained in the work orders and SMS messages, dates and times of calls
        and SMS messages, and the parties’ phone numbers. Zippity also reserves
        the right to monitor communications throughout the Zippity Site and
        Zippity Platform, and to terminate access/use by any user violating any
        applicable terms or policies or any applicable laws.
      </p>
      <p className="privacy-paragraph">
        <span>Online Surveys and Contests</span>
      </p>
      <p className="privacy-paragraph">
        From time to time, Zippity may conduct online User surveys to collect
        information about our Users’ preferences. Unless otherwise indicated at
        the time of the survey, each survey is optional and, if you choose to
        respond, your responses will be stored anonymously. Similarly, Zippity
        may offer contests to qualifying Users. We will typically collect
        contact and demographic information from survey participants, including
        name, email address and mailing address. Each survey/contest may be
        subject to additional privacy notices and terms and conditions provided
        at the time of the survey/contest, which will take precedence to the
        extent they conflict with this Privacy Policy.
      </p>
      <p className="privacy-paragraph">
        <span>Anonymized/Aggregated Data</span>
      </p>
      <p className="privacy-paragraph">
        There may be circumstances where we collect or retain information,
        including some of the above-described categories, in an anonymized
        and/or aggregated form, such that it cannot reasonably be associated
        with you, for statistical analysis, product development, marketing,
        research and other uses. We consider such information to be non-personal
        information that is not subject to this Privacy Policy.
      </p>
      <h5 className="privacy-paragraph">3. Sharing of Personal Information</h5>
      <p className="privacy-paragraph">
        We may share personal information with the following categories of third
        parties:
      </p>
      <ul>
        <li>
          <span>Other Users</span>: We may share your personal information with
          other Users who have a reasonable need to know such information, such
          as a Professional who needs access to the contact information and job
          details for a work order placed by a Buyer or an enterprise customer
          who needs access to an end user’s personal information in order to
          fulfill an appointment placed via a Partner Site.
          <ul>
            <li>
              <span>Service Providers:</span> We may share your personal
              information with third-party service providers who assist with
              aspects of our business, including sending emails and SMS
              messages, operating and hosting the Zippity Site and Zippity
              Platform, and marketing and advertising our products.
              <ul>
                <li>
                  <span>Other Intermediaries</span>: We may share your personal
                  information with third parties who assist with advertising and
                  marketing of our business and performing business analytics,
                  with a third-party credit card processing company, and with a
                  third-party provider of vehicle service records. Some of these
                  third parties may use this information for their own marketing
                  and other secondary commercial purposes not directly related
                  to the services they provide to Zippity.
                  <ul>
                    <li>
                      <span>Zippity Affiliates and Successors</span>: We may
                      share your personal information with affiliated or
                      successor entities, including in connection with a sale,
                      reorganization, consolidation, or merger.
                      <ul>
                        <li>
                          <span>Other Third Parties</span>: We may share your
                          personal information, including with government bodies
                          or law enforcement agencies, when we believe it to be
                          necessary for protecting the safety and well-being of
                          Zippity, our users and others or complying with
                          applicable laws and regulations
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h5 className="privacy-paragraph">4. Security of Personal Information</h5>
      <p className="privacy-paragraph">
        We take reasonable measures designed to protect personal information
        from unauthorized access and against unlawful processing, accidental
        loss, destruction, and damage. For example, our Payment System, as
        designed by our payment processor to facilitate secure treatment of
        payment information, is operated using secure Internet connections,
        using SSL (Secure Sockets Layer) encryption. We cannot, however,
        guarantee the security of your personal information, particularly as it
        travels over the Internet. As such, we encourage you to exercise caution
        in determining what information you disclose via the Internet.
      </p>
      <h5 className="privacy-paragraph">
        5. Retention and Deletion of Personal Information; Terminating Your
        Account
      </h5>
      <p className="privacy-paragraph">
        We will not retain your personal information for longer than necessary,
        taking into account this Privacy Policy, internal record retention
        schedules, applicable legal obligations, and whether and when you have
        terminated your account. If you terminate your account, your personal
        information may not be recoverable, though you may continue to receive
        email communications for a short period of time until we have updated
        all of our databases.
      </p>
      <h5 className="privacy-paragraph">6. Your Privacy Choices and Rights</h5>
      <p className="privacy-paragraph">
        You may opt-out of receiving marketing emails at any time by clicking on
        the unsubscribe link in any marketing email you receive from us.
      </p>
      <p className="privacy-paragraph">
        You have the option of disabling cookies via your browser
        settings/preferences. If you disable cookies on your browser, please
        note that some parts of the Zippity Site and Zippity Platform may not
        function as effectively or may be considerably slower.
      </p>
      <p className="privacy-paragraph">
        On the Zippity Site, you may update your registration information using
        the appropriate tools in the Zippity navigation bar, and you may update
        your payment information using the appropriate page.
      </p>
      <p className="privacy-paragraph">
        You can also contact us at any time using the contact information listed
        in the last section of this Privacy Policy to request we do any of the
        following: (i) erase or delete all or some of your personal information;
        (ii) change, update, or correct your personal information; (iii)
        restrict how we use all or some of your personal information; (iv)
        access your personal information and, where applicable and feasible,
        request to receive that information in a commonly used electronic format
        (or ask for this information to be provided in that format to a third
        party); or (v) disclose what personal information we collect, use,
        disclose or sell. We will consider and respond to any such requests
        promptly and otherwise in accordance with applicable laws.
      </p>
      <h5 className="privacy-paragraph">
        7. Transmittal of Data to and Processing of Data in the United States
      </h5>
      <p className="privacy-paragraph">
        Zippity is located in the United States. Please be aware that any
        information you provide to us may be transferred to and processed in the
        United States and be subject to United States law. The privacy and data
        protection laws in the United States may not be equivalent to the laws
        in your country of residence.
      </p>
      <h5 className="privacy-paragraph">8. Children</h5>
      <p className="privacy-paragraph">
        The Zippity Site and Zippity Platform are not intended for children
        under the age of 16. We do not knowingly collect or solicit personal
        information from anyone under 16. If we learn we have collected or
        received personal information from a child under 16 without verification
        of parental consent, we will delete that information as quickly as
        possible. If you believe we might have any such information, please
        contact us at legal@getzippity.com.
      </p>
      <h5 className="privacy-paragraph">9. Contacting Us</h5>
      <p className="privacy-paragraph">
        If you have any questions about this Privacy Policy or the practices of
        this Site, you may contact us at legal@getzippity.com.
      </p>
      <p>
          Zippity by SimpleTire is integrating with Google Calendar to allow users
          to display appointments booked through Zippity in their calendar on their
          Google account, receive live updates in Zippity when changes are made to
          their events in Google, and modify schedule available in Zippity based on
          the time and duration of events in the Google calendar. Therefore, users
          must provide authorization for Zippity to write to and read from Google
          Calendar. Additionally, the Zippity app requires the email of the authenticated
          user, as the app displays the linked account and provides the user the option to
          change the linked account.
      </p>
      <p>
          Zippity by SimpleTire's use and transfer to any other app of information received
          from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy"
                                             title="Google API Services User Data Policy" target="_blank">
          Google API Services User Data Policy </a>, including the
          Limited Use requirements.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
